import { forwardRef } from 'react'
import CurrencyInputField from 'react-currency-input-field'
import type { CurrencyInputProps } from 'react-currency-input-field/dist/components/CurrencyInputProps'
import { cn } from 'services/utils'

const MAX_DECIMALS = 2

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ allowDecimals, value, onValueChange, className, ...rest }, ref) => {
    const onChangeHandler = (val: string | undefined) => {
      if (!val) onValueChange?.('')
      else onValueChange?.(val)
    }

    return (
      <CurrencyInputField
        {...rest}
        ref={ref}
        value={value || 0}
        prefix='$'
        allowDecimals={allowDecimals}
        decimalScale={allowDecimals ? MAX_DECIMALS : 0}
        decimalsLimit={allowDecimals ? MAX_DECIMALS : 0}
        className={cn(
          'flex-grow border border-[#BCCDE5] bg-white h-12 px-4 rounded-lg mb-2 w-full',
          className ?? ''
        )}
        onValueChange={onChangeHandler}
      />
    )
  }
)

CurrencyInput.displayName = 'CurrencyInput'

export { CurrencyInput }
