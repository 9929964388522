import { useState } from 'react'
import { Trash2 } from 'lucide-react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from 'components/ui/AlertDialog'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useDeleteEntityMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { useQueryClient } from '@tanstack/react-query'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import type { UserInfo } from 'types/api-types'
import { DialogHeader } from 'components/core/Modal'

interface Props {
  user: UserInfo | undefined
  id: number | undefined
  name: string
}

export function RemoveEntity({ user, id, name }: Props) {
  const { toast } = useToast()

  const queryClient = useQueryClient()

  const [open, setOpen] = useState<boolean>(false)
  const { mutate, isLoading } = useDeleteEntityMutate()

  const removeHandler = () => {
    if (!id) return

    mutate(id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: userKeyFactory.entities(user?.id),
          type: 'all'
        })

        toast({
          variant: 'success',
          description: 'Entity was removed',
          duration: 2000
        })
        setOpen(false)
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant='ghost' size='sm'>
          <Trash2 color='#D06767' className='w-4 sm:w-5 h-4 sm:h-5' />
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent className='rounded-md max-w-3xl'>
        <AlertDialogHeader>
          <DialogHeader onClose={() => setOpen(false)}>
            <AlertDialogTitle className='text-2xl montserrat'>Remove entity</AlertDialogTitle>
          </DialogHeader>
          <span>Are you sure you want to remove {name}</span>
        </AlertDialogHeader>

        <AlertDialogFooter className='mt-4 space-y-2 sm:space-y-0 flex-col sm:flex-row sm:justify-start'>
          <Button className='flex-1 max-w-[262px]' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            type='button'
            variant='destructive'
            loading={isLoading}
            className='ml-2 flex-1 max-w-[262px]'
            onClick={removeHandler}
          >
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
