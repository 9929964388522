import { useQueryClient } from '@tanstack/react-query'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/Dialog'
import { Skeleton } from 'components/ui/Skeleton'
import { useMemberQuery } from 'hooks/api/useQuery.hooks'
import { ManageDetailsDialogProvider } from 'contexts/ManageDetailsDialog.context'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import type { Member } from 'types/api-types'
import { EditUser } from './components/EditUser'

function DialogSkeleton() {
  return (
    <div className='flex flex-col space-y-2'>
      <Skeleton className='h-10 w-full' />
      <Skeleton className='h-5 w-full' />
      <Skeleton className='h-5 w-full' />
      <Skeleton className='h-5 w-full' />
    </div>
  )
}

interface Props {
  member: Member | null
  setMemberToEditStatus: (member: Member | null) => void
  isClientDetails?: boolean
}

export function ManageDetailsDialog({ member, setMemberToEditStatus }: Props) {
  const queryClient = useQueryClient()

  const { data: currentMember, isLoading, isFetching } = useMemberQuery(member?.user_id)

  const onToggleOpen = (status: boolean) => {
    if (status) setMemberToEditStatus(member)
    else setMemberToEditStatus(null)
  }

  const userUpdatedHandler = async () => {
    if (!currentMember) return
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: userKeyFactory.member(currentMember.id),
        refetchType: 'all'
      }),
      queryClient.refetchQueries({
        queryKey: userKeyFactory.user(),
        exact: true
      })
    ])
  }

  return (
    <Dialog open={!!member} onOpenChange={onToggleOpen}>
      <DialogContent className='rounded-md max-w-3xl'>
        <ManageDetailsDialogProvider
          state={{ user: currentMember }}
          handlers={{
            onCloseManageDetailsDialog: () => onToggleOpen(false),
            onUpdateMember: userUpdatedHandler
          }}
        >
          {!currentMember || (isLoading && isFetching) ? (
            <DialogSkeleton />
          ) : (
            <>
              <DialogHeader>
                <DialogTitle className='text-2xl'>Admin details</DialogTitle>
              </DialogHeader>

              <EditUser member={currentMember} />
            </>
          )}
        </ManageDetailsDialogProvider>
      </DialogContent>
    </Dialog>
  )
}
