import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import z from 'zod'
import { Check } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from 'components/ui/Dialog'
import { UserInfo } from 'types/api-types'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { PasswordInput } from 'components/ui/PasswordInput'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useToast } from 'hooks/userToast.hooks'
import { RadioGroup, RadioGroupItem } from 'components/ui/RadioGroup'
import {
  useCreateClientPasswordMutate,
  useSendResetPasswordEmailMutate
} from 'hooks/api/useMutation.hooks'

interface Props {
  user: UserInfo | undefined
}

const passwordSchema = z
  .object({
    type: z.enum(['send', 'create']),
    password: z.string().optional()
  })
  .superRefine(({ type, password }, ctx) => {
    if (type === 'create' && (!password || password.length < 8)) {
      ctx.addIssue({
        code: 'custom',
        message: 'Must have at least 8 characters',
        path: ['password']
      })
    }
  })
type PasswordSchema = z.infer<typeof passwordSchema>

export function ResetClientPasswordDialog({ user }: Props) {
  const { mutate: createClientPasswordMutate, isLoading: isCreateClientPasswordLoading } =
    useCreateClientPasswordMutate()
  const { mutate: sendResetEmailMutate, isLoading: isSendResetEmailLoading } =
    useSendResetPasswordEmailMutate()

  const { toast } = useToast()

  const [open, setOpen] = useState<boolean>(false)

  const form = useForm<PasswordSchema>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      type: 'create',
      password: ''
    }
  })

  const handleSubmit: SubmitHandler<PasswordSchema> = values => {
    if (!user) return

    if (values.type === 'send') {
      sendResetEmailMutate(user.username, {
        onSuccess: () => {
          toast({
            description: (
              <span className='flex items-center space-x-2'>
                <span>Reset password email has been sent</span>
                <Check color='#52B749' className='w-4 h-3' />
              </span>
            ),
            duration: 2000
          })
          setOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      })
    } else if (values.type === 'create' && values.password) {
      createClientPasswordMutate(
        { userId: user.id, password: values.password },
        {
          onSuccess: () => {
            toast({
              description: (
                <span className='flex items-center space-x-2'>
                  <span>Client password has been created</span>
                  <Check color='#52B749' className='w-4 h-3' />
                </span>
              ),
              duration: 2000
            })
            setOpen(false)
          },
          onError: error => {
            toast({
              variant: 'destructive',
              description: error.message ?? 'Something went wrong',
              duration: 2000
            })
          }
        }
      )
    }
  }

  const isCreateType = form.watch('type') === 'create'

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className='w-full text-left'>Reset password</DialogTrigger>

      <DialogContent className='max-w-[737px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl font-semibold montserrat'>
            Reset Password for {user?.name ?? ''}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form className='mt-4' onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-1'>
              <FormField
                control={form.control}
                name='type'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className='flex flex-col space-y-4'
                      >
                        <FormItem className='flex items-center space-x-3 space-y-0'>
                          <FormControl>
                            <RadioGroupItem value='send' />
                          </FormControl>
                          <FormLabel className='text-base'>Send password reset email</FormLabel>
                        </FormItem>

                        <FormItem>
                          <div className='flex items-center space-x-3 space-y-0'>
                            <FormControl>
                              <RadioGroupItem value='create' />
                            </FormControl>
                            <FormLabel className='text-base'>Create password</FormLabel>
                          </div>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {isCreateType && (
                <FormField
                  control={form.control}
                  name='password'
                  render={({ field }) => {
                    return (
                      <FormItem className='max-w-[485px] ml-8'>
                        <FormControl>
                          <PasswordInput placeholder='******' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
              )}
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isSendResetEmailLoading || isCreateClientPasswordLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isSendResetEmailLoading || isCreateClientPasswordLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-[#52B749]'
              >
                Reset
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
