import { useReducer } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Button } from 'components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { EditSummaryDialog } from './dialogs/EditSummaryDialog'
import { DealLoader } from './layouts/Loader'

interface Props {
  id: string
}

export function Summary({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)

  const [open, setOpen] = useReducer<(st: boolean) => boolean>(st => !st, false)

  if (!opportunity) return <DealLoader.Card />

  return (
    <>
      <Card className='h-full'>
        <CardHeader className='flex items-center flex-row'>
          <CardTitle>Summary</CardTitle>
          <Button
            type='button'
            variant='ghost'
            size='sm'
            className='ml-auto text-success'
            onClick={setOpen}
          >
            Edit
          </Button>
        </CardHeader>

        <CardContent className='space-y-4'>
          <div className='flex flex-wrap sm:space-x-8'>
            {opportunity.company_url && (
              <a
                href={opportunity.company_url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-success font-semibold'
              >
                {opportunity.company_url}
              </a>
            )}
            {opportunity.schedule_call_url && (
              <a
                href={opportunity.schedule_call_url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-success font-semibold'
              >
                Schedule Call URL
              </a>
            )}
          </div>

          <div>
            {opportunity.long_description_html &&
              ReactHtmlParser(opportunity.long_description_html)}
          </div>
        </CardContent>
      </Card>

      {open && <EditSummaryDialog open={open} toggleOpen={setOpen} opportunity={opportunity} />}
    </>
  )
}
