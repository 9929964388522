import { useState } from 'react'
import EditAccountInfo from 'components/EditAccountInfo'
import { Dialog, DialogContent, DialogTrigger } from 'components/ui/Dialog'
import { UserInfo } from 'types/api-types'

interface Props {
  user: UserInfo | undefined
  updateMember: (user: UserInfo) => void
}

export function EditAccount({ user, updateMember }: Props) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className='w-full text-left'>Edit</DialogTrigger>

      <DialogContent className='rounded-md max-w-3xl'>
        <EditAccountInfo
          user={user}
          userUpdated={updateMember}
          close={() => setOpen(false)}
          closeAll={undefined}
        />
      </DialogContent>
    </Dialog>
  )
}
