import { type ColumnDef } from '@tanstack/react-table'
import type { CommitmentsResponse } from 'types/api-types'
import { dateFormat } from 'services/TimeUtils'
import { dollarFormat } from 'constants/DollarsFormat'
import { Checkbox } from 'components/ui/Checkbox'
import ChampionLogo from 'assets/images/champion.png'
import MvpLogo from 'assets/images/mvp.png'

interface Props {
  onNameClick: (id: number) => void
}

const numericSort = (rowA: any, rowB: any, columnId: string) => {
  const a = rowA.getValue(columnId)
  const b = rowB.getValue(columnId)

  // Handle null/undefined values
  if (a == null && b == null) return 0
  if (a == null) return -1 // null values go to top
  if (b == null) return 1

  return +a - +b
}

export function getColumn({ onNameClick }: Props) {
  const columns: ColumnDef<CommitmentsResponse>[] = [
    {
      accessorKey: 'select',
      header: ({ table }) => {
        const handleCheckedChange = (checked: boolean) => {
          // Convert boolean to the expected event format that `getToggleAllPageRowsSelectedHandler` wants.
          const event = { target: { checked } }
          table.getToggleAllPageRowsSelectedHandler()(event)
        }
        return (
          <Checkbox checked={table.getIsAllRowsSelected()} onCheckedChange={handleCheckedChange} />
        )
      },
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onCheckedChange={row.getToggleSelectedHandler()}
          />
        )
      },
      size: 50,
      enableSorting: false
    },
    {
      accessorKey: 'name',
      header: () => <span>Name</span>,
      cell: ({ row }) => {
        const { name, user } = row.original
        return (
          <button
            type='button'
            className='text-left underline'
            onClick={() => onNameClick(user.id)}
          >
            {name}
          </button>
          // <Link to={`/admin/clients/${user?.id}`} className='underline'>
          //   {name}
          // </Link>
        )
      }
    },
    {
      accessorKey: 'origin',
      header: () => <span>Origin</span>,
      cell: ({ row }) => {
        const { platform } = row.original
        if (platform === 'mvp') return <img src={MvpLogo} alt='MVP' className='max-w-[40px]' />
        if (platform === 'champion')
          return <img src={ChampionLogo} alt='Champion' className='max-w-[40px]' />
        return <span>{platform}</span>
      }
    },
    {
      accessorKey: 'created_at',
      header: () => <span>Date</span>,
      accessorFn: row => {
        return row.created_at
      },
      cell: ({ row }) => {
        const { created_at } = row.original
        return dateFormat(created_at)
      }
    },
    {
      accessorKey: 'fund_commitment',
      header: () => <span>Fund Commitment</span>,
      accessorFn: row => {
        return row.vintage_investment_amount ?? row.collection_investment_amount
      },
      cell: ({ row }) => {
        const { vintage_investment_amount, collection_investment_amount } = row.original
        return dollarFormat(vintage_investment_amount ?? collection_investment_amount)
      }
    },
    {
      accessorKey: 'amount_requested',
      header: () => <span>Requested Amount</span>
    },
    {
      accessorKey: 'fee_years',
      header: () => <span>Final Years</span>,
      sortingFn: numericSort
    },
    {
      accessorKey: 'final_amount_invested',
      header: () => <span>Final Invested</span>,
      sortingFn: numericSort
    },
    {
      accessorKey: 'final_amount_fee',
      header: () => <span>Final Fees</span>,
      sortingFn: numericSort
    },
    {
      accessorKey: 'final_amount_total',
      header: () => <span>Final Total</span>,
      sortingFn: numericSort
    },
    {
      accessorKey: 'distributed_amount',
      header: () => <span>Disbtributed</span>
    },
    {
      accessorKey: 'carry_share',
      header: () => <span>Carry Share</span>
    },
    {
      accessorKey: 'client_notified_at',
      header: () => <span>Notified</span>,
      cell: ({ row }) => {
        const { client_notified_at } = row.original
        if (client_notified_at) return `Sent ${dateFormat(client_notified_at)}`
        return '-'
      }
    }
  ]

  return columns
}
