import { dollarFormat } from '../constants/DollarsFormat'

function CurrencyLabel({ children }) {
  return <div className='text-slate-700 text-sm md:text-base'>{children}</div>
}

export default function EntityCommitmentFeeAndCapital({
  totalInvestment = 0,
  feeAmount = 0,
  totalCapital = 0,
  initialCapital = 0,
  multipleEntities = false,
  isDealshare = false,
  oneTimeFee = false
}) {
  return (
    <div className='space-y-2 mt-2'>
      <CurrencyLabel>
        Total Investment:{' '}
        {dollarFormat(multipleEntities || isDealshare ? totalInvestment : totalInvestment || 0)}
      </CurrencyLabel>
      {isDealshare && (
        <>
          <CurrencyLabel>
            {`${oneTimeFee ? `One-time` : `Lifetime`} Management Fee: ${dollarFormat(feeAmount)}`}
          </CurrencyLabel>
          <CurrencyLabel>
            Total Capital: <strong>{`${dollarFormat(totalCapital)}`}</strong>
          </CurrencyLabel>
          <CurrencyLabel>
            Initial Capital Call: <strong>{`${dollarFormat(initialCapital)}`}</strong>
          </CurrencyLabel>
        </>
      )}
    </div>
  )
}
