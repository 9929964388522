import { type ColumnDef } from '@tanstack/react-table'
import { dollarFormat } from 'constants/DollarsFormat'

const DEALSHARE_NAME_MAPPER: Record<string, string> = {
  mvp: 'MVP Co-Invests',
  champion: 'Champion Co-Invests',
  all: 'Total Co-Invests',
  fundInvestment: 'Fund Investments',
  total: 'Total'
}
const NAME_MAPPER: Record<string, string> = {
  mvp: 'MVP Users',
  champion: 'Champion Users',
  all: 'Total',
  fundInvestment: 'Fund Investments',
  total: 'Total'
}

export const totalColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'type',
    header: () => <span>Name</span>,
    accessorFn: row => {
      const { type, opportunity_type } = row
      if (opportunity_type === 'DealShare') return DEALSHARE_NAME_MAPPER[type]
      return NAME_MAPPER[type]
    }
  },
  {
    accessorKey: 'total_amount_requested',
    header: () => <span>Requested Amount</span>,
    accessorFn: row => {
      const { total_amount_requested } = row.data ?? {}
      return dollarFormat(total_amount_requested, true)
    }
  },
  {
    accessorKey: 'total_final_invested',
    header: () => <span>Final Invested</span>,
    accessorFn: row => {
      const { total_final_invested } = row.data ?? {}

      return dollarFormat(total_final_invested, true)
    }
  },
  {
    accessorKey: 'total_final_fees',
    header: () => <span>Final Fees</span>,
    accessorFn: row => {
      const { total_final_fees } = row.data ?? {}
      return dollarFormat(total_final_fees, true)
    }
  },
  {
    accessorKey: 'total_final_total',
    header: () => <span>Final Total</span>,
    accessorFn: row => {
      const { total_final_total } = row.data ?? {}
      return dollarFormat(total_final_total, true)
    }
  }
]
