import React, { useState } from 'react'
import styled from 'styled-components'
import sv from '../themes/styles'
import Button from './core/Button'
import Textarea from './core/fields/Textarea'
import SavingProgress from './SavingProgress'
import MultiEmail from './core/fields/MultiEmail'
import { H2, Text } from '../constants/StyleComponents'
import { authenticatedFetch } from '../services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = styled.div``

const Header = styled.div`
  ${sv.flexRow};
  padding-bottom: ${sv.appPadding}px;
  border-bottom: ${sv.borderStyle};
  margin-bottom: ${sv.appPadding}px;
`

const Info = styled.div`
  flex: 1;
`

const Actions = styled.div`
  ${sv.flexRow};
  justify-content: flex-end;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function ShareDealForm({ deal, close }) {
  const [recipients, setRecipients] = useState([])
  const defaultMessage = 'Check out this investment opportunity!'
  const [message, setMessage] = useState(defaultMessage)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [validationWarning, setValidationWarning] = useState(null)
  const [error, setError] = useState(null)

  const handleSubmit = () => {
    setError(null)
    if (recipients.length === 0) {
      setValidationWarning('You must enter at least one valid email address.')
      return
    }
    setSubmitting(true)
    authenticatedFetch(`opportunities/${deal.id}/share`, {
      method: 'POST',
      body: JSON.stringify({
        emails: recipients,
        message: message
      })
    })
      .then(r => {
        setSuccess(true)
      })
      .catch(err => {
        setSubmitting(false)
        setError((err && err.message) || 'Something went wrong, try again.')
      })
  }

  const handleCancel = () => {
    setRecipients([])
    setMessage(defaultMessage)
    setValidationWarning(null)
    setError(null)
    setSuccess(false)
    setSubmitting(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Info>
          <H2>Share this opportunity</H2>
          <Text>
            We hope that you will find our investments compelling enough to share with a colleague,
            family member, or friend. Please enter the email addresses for the people with whom
            you’d like to share this opportunity.
          </Text>
        </Info>
      </Header>

      {validationWarning && <Text color={sv.colors.danger}>{validationWarning}</Text>}

      {error && <Text color={sv.colors.danger}>{error}</Text>}

      <MultiEmail
        setRecipients={setRecipients}
        recipients={recipients}
        label='recipients email addresses'
      />

      <Textarea label='message (optional)' value={message} onChange={value => setMessage(value)} />
      <Actions>
        <Button className='cancel' subtle marginRight label='Cancel' action={handleCancel} />
        <Button label='Submit' action={() => handleSubmit()} />
      </Actions>

      <SavingProgress
        saving={submitting}
        success={success}
        close={handleCancel}
        message='Thank you for sharing this deal!'
      />
    </Container>
  )
}
