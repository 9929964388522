import { type ColumnDef } from '@tanstack/react-table'
import type { OpportunityResponse } from 'types/api-types'
import configuration from 'services/config'
import { dateFormat } from 'services/TimeUtils'
import { cn } from 'services/utils'
import { getLogoUrl } from '../deal/DealHeader'

const statusMapper: Record<string, { className: string; mobileClassName: string; label: string }> =
  {
    live: {
      // active
      className: 'text-[#6DB557] border-[#6DB557] bg-green-50/20',
      mobileClassName: 'bg-[#6DB557]',
      label: 'Live'
    },
    draft: {
      // inactive
      className: 'text-[#F88A0A] border-[#F88A0A] bg-orange-50/20',
      mobileClassName: 'bg-[#F88A0A]',
      label: 'Draft'
    },
    archived: {
      className: 'text-[#F47777] border-[#F47777] bg-red-50/20',
      mobileClassName: 'bg-[#F47777]',
      label: 'Archived'
    }
  }

export const columns: ColumnDef<OpportunityResponse>[] = [
  {
    accessorKey: 'name',
    header: () => <span>Deal</span>,
    cell: info => {
      const { original } = info.row
      const imageSrc = getLogoUrl(original.logo?.url)

      return (
        <>
          <span className='hidden md:flex items-center space-x-3'>
            <div style={{ background: original.background_color }} className='rounded-sm'>
              <img
                src={imageSrc}
                alt={original.name}
                className='min-w-[50px] max-w-[50px] mdlg:min-w-[70px] mdlg:max-w-[70px] h-[30px] w-full md:w-auto object-contain px-1'
                onError={({ currentTarget }) => {
                  currentTarget.className =
                    'h-[30px] min-w-[50px] max-w-[50px] mdlg:min-w-[70px] invisible'
                }}
              />
            </div>
            <span className='text-[#6DB557] font-semibold text-sm mdlg:text-base'>
              {original.name}
            </span>
          </span>

          <div className='flex md:hidden items-center space-x-3'>
            <div style={{ backgroundColor: original.background_color }} className='rounded-sm'>
              <img
                src={imageSrc}
                alt={original.name}
                className='max-w-[48px] h-[30px] w-full md:w-auto object-contain'
                onError={({ currentTarget }) => {
                  currentTarget.className = 'h-[30px] min-w-[48px] max-w-[48px] invisible'
                }}
              />
            </div>
            <div className='flex flex-col flex-1 space-y-1'>
              <div className='flex justify-between'>
                <span className='text-[#6DB557] font-semibold text-sm'>{original.name}</span>
                <span className='text-sm'>{original.dealshare_stage?.name ?? '-'}</span>
              </div>

              <div className='flex justify-between'>
                <span className='text-sm'>{original.mapped_opportunity_type}</span>
                <span className='text-sm'>{dateFormat(original.closes)}</span>
              </div>
            </div>
            <span
              className={cn('rounded-full w-2 h-2', statusMapper[original.status]?.mobileClassName)}
            />
          </div>
        </>
      )
    },
    meta: { thClassName: 'hidden md:table-cell' },
    size: 300
  },
  {
    accessorKey: 'mapped_opportunity_type',
    header: () => <span>Type</span>,
    cell: info => {
      const { mapped_opportunity_type } = info.row.original
      return (
        <span className='hidden md:inline text-sm mdlg:text-base'>{mapped_opportunity_type}</span>
      )
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' }
  },
  {
    accessorFn: row => row.dealshare_stage?.name ?? '',
    id: 'dealshare_stage.name',
    header: () => <span>Stage</span>,
    cell: info => {
      const { dealshare_stage } = info.row.original
      return (
        <span className='hidden md:inline text-sm mdlg:text-base'>
          {dealshare_stage?.name ?? '-'}
        </span>
      )
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' }
  },
  {
    accessorKey: 'mapped_status',
    header: () => <span>Status</span>,
    cell: info => {
      const { mapped_status } = info.row.original
      return (
        <span
          className={cn(
            'border rounded-full px-2 py-1 font-medium text-sm',
            statusMapper[mapped_status]?.className
          )}
        >
          {statusMapper[mapped_status]?.label}
        </span>
      )
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' }
  },
  {
    accessorKey: 'closes',
    header: () => <span>Closes</span>,
    cell: info => {
      const { closes } = info.row.original
      return <span className='text-sm mdlg:text-base'>{dateFormat(closes)}</span>
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' }
  }
]
