import * as React from "react"
import { cn } from 'services/utils'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "primary" | "secondary" | "success" | "danger" | "warning"
  asChild?: boolean
}

const badgeVariants = cva(
  'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
  {
    variants: {
      variant: {
        default: 'bg-gray-100 text-gray-800',
        primary: 'bg-blue-100 text-blue-800',
        secondary: 'bg-gray-100 text-gray-800',
        success: 'bg-green-100 text-green-800',
        danger: 'bg-red-100 text-red-800',
        warning: 'bg-yellow-100 text-yellow-800'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

const Badge: React.FC<BadgeProps> = ({ asChild, variant, className, children, ...props }) => {
  const Comp = asChild ? Slot : 'span'
  return (
    <Comp className={cn(badgeVariants({ variant, className }))} {...props}>
      {children}
    </Comp>
  )
}

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

Badge.displayName = 'Badge'

export { Badge }
export type { BadgeProps }
