import { Button } from 'components/ui/Button'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useRemoveMemberFromOrganizationMutate } from 'hooks/api/useMutation.hooks'
import type { Member, UserInfo } from 'types/api-types'
import { useToast } from 'hooks/userToast.hooks'
import { DialogHeader } from 'components/core/Modal'

interface Props {
  currentUser: UserInfo
  member: Member | null
  setMemberToDelete: (member: Member | null) => void
}

export function RemoveMember({ currentUser, member, setMemberToDelete }: Props) {
  const { toast } = useToast()

  const { mutate, isLoading } = useRemoveMemberFromOrganizationMutate()

  const removeHandler = () => {
    if (!currentUser || !member) return

    const data = { id: currentUser.organization.id, userId: currentUser.id, email: member.username }
    mutate(data, {
      onSuccess: () => {
        toast({
          variant: 'success',
          description: 'Member was removed',
          duration: 2000
        })
        setMemberToDelete(null)
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  return (
    <AlertDialog open={!!member}>
      <AlertDialogContent className='rounded-md max-w-3xl'>
        <AlertDialogHeader>
          <DialogHeader onClose={() => setMemberToDelete(null)}>
            <AlertDialogTitle className='text-2xl montserrat'>Remove Member</AlertDialogTitle>
          </DialogHeader>
          <AlertDialogDescription className='text-base montserrat text-[#4A4A4A] break-all'>
            Are you sure you want to remove this member?
          </AlertDialogDescription>
        </AlertDialogHeader>

        <span>{member?.username}</span>

        <AlertDialogFooter className='mt-4 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='ml-2 flex-1 max-w-[262px]'
            onClick={() => setMemberToDelete(null)}
          >
            Cancel
          </Button>
          <LoadingButton
            type='button'
            variant='destructive'
            loading={isLoading}
            size='sm'
            className='flex-1 max-w-[262px]'
            onClick={removeHandler}
          >
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
