import { useState } from 'react'
import { useParams } from 'react-router-dom'
import HeaderNew from 'components/core/HeaderNew'
import ScrollContainer from 'components/core/ScrollContainer'
import { CommitmentsTable } from 'components/core/v2/commitments/Commitments'
import { CommitmentsTotalTable } from 'components/core/v2/commitments/CommitmentsTotal'
import { CommitmentsCSVExport } from 'components/core/v2/commitments/CommitmentsCSVExport'

export default function DealCommitmentsRoute() {
  const params: { id: string } = useParams()

  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  return (
    <ScrollContainer
      hasHeader
      className=''
      goToTop={undefined}
      setGoToTop={undefined}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
    >
      <HeaderNew />

      <div className='max-w-screen-max mx-auto px-4 isolate'>
        <div>
          <CommitmentsTable id={params.id} />
          <CommitmentsTotalTable id={params.id} />
        </div>

        <CommitmentsCSVExport id={params.id} />
      </div>
    </ScrollContainer>
  )
}
