import React, { useState } from 'react'
import { useParams } from 'react-router'
import OpportunityCarries from '../../components/opportunity/OpportunityCarries'
import ScrollContainer from '../../components/core/ScrollContainer'
import Header from '../../components/core/Header'
import { Content } from '../../constants/StyleComponents'
import HeaderNew from 'components/core/HeaderNew'

/**
 * Component for showing commitments for a deal
 * @returns {JSX.Element}
 * @constructor
 */
export default function DealCommitmentsRoute({ history }) {
  const { id } = useParams()

  const [isScrolled, setIsScrolled] = useState(false)

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push(`/admin/deals/${id}`)
  }

  return (
    <ScrollContainer isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      {/* <Header onAdmin isScrolled={isScrolled} leftAction={headerAction} /> */}
      <HeaderNew
        className='black-header'
        onAdmin
        isScrolled={isScrolled}
        leftAction={headerAction}
      />

      <Content isAdmin>
        <OpportunityCarries id={id} />
      </Content>
    </ScrollContainer>
  )
}
