import { type ComponentPropsWithoutRef } from 'react'
import { Link } from 'react-router-dom'
import { flexRender, Table as ReactTable } from '@tanstack/react-table'
import { CircleArrowDown, CircleArrowUp } from 'lucide-react'
import { cn } from 'services/utils'

interface Props<TData> extends ComponentPropsWithoutRef<'div'> {
  table: ReactTable<TData>
  isClickableRow?: boolean
}

export function EditableTable<TData>(props: Props<TData & { linkUrl?: string }>) {
  const { table, className, isClickableRow = false } = props

  return (
    <div className={className}>
      <table className='w-full shadow-table'>
        <thead className='sticky top-0'>
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      className={cn(
                        'py-4 px-4 bg-gray-100',
                        header.column.columnDef.meta?.thClassName ?? ''
                      )}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <button
                          className='flex items-center space-x-1'
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sorting descending'
                                : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <CircleArrowUp className='w-4 h-4' />,
                            desc: <CircleArrowDown className='w-4 h-4' />
                          }[header.column.getIsSorted() as string] ?? null}
                        </button>
                      )}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>

        <tbody className="before:content-['-'] before:block before:leading-[2px] before:indent-[-9999px]">
          {table.getRowModel().rows.map(row => {
            if (isClickableRow && row.original.linkUrl)
              return (
                <tr
                  key={row.id}
                  className='group border border-[#eee] hover:shadow-table-hover hover:cursor-pointer'
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        className={cn(
                          'bg-[#f8fafc] group-hover:bg-[#f8fbf6]',
                          cell.column.columnDef.meta?.tdClassName ?? ''
                        )}
                      >
                        <Link to={row.original.linkUrl ?? ''} className='block px-4 py-4 '>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Link>
                      </td>
                    )
                  })}
                </tr>
              )
            return (
              <tr key={row.id} className='group border border-[#eee] hover:shadow-table-hover'>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className={cn(
                        'px-4 py-4 bg-[#f8fafc] group-hover:bg-[#f8fbf6]',
                        cell.column.columnDef.meta?.tdClassName ?? ''
                      )}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
