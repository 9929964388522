import React from 'react'
import styled from 'styled-components'
import sv from '../themes/styles'
import { H2 } from '../constants/StyleComponents'
import MediaItem from './MediaItem'
import { PLATFORMS } from '../services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = styled.div``

const Title = styled(H2)`
  margin-bottom: ${sv.grid * 2}px;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function DataRoom({ items, close, deal, history, isClosed }) {
  return (
    <Container>
      <Title>Data Room</Title>
      {items
        .filter(
          item =>
            (item.visible_on_champion && PLATFORMS.CHAMPION) ||
            (item.visible_on_mvp && PLATFORMS.MVP)
        )
        .map((item, index) => (
          <MediaItem key={index} item={item} opportunity={deal} history={history} />
        ))}
    </Container>
  )
}
