import configuration from 'services/config'
import { dollarShortener } from 'constants/DollarsFormat'
import { Skeleton } from 'components/ui/Skeleton'

interface CommitmentOpportunityProps {
  logo: string
  name: string
  minimum: number
  maximum?: number,
  isLoading?: boolean
}

const CommitmentOpportunity: React.FC<CommitmentOpportunityProps> = ({ name, logo, minimum, maximum, isLoading = false }) => {
  return (
    <div className="flex items-start space-x-4 mt-4">
      {
        isLoading ? <Skeleton className='w-24 h-24' /> :
        <img
          src={configuration.apiBaseUrl + logo}
          alt={name}
          className="w-24 h-24 object-cover"
        />
      }
      {
        isLoading ?
        <div className="flex flex-col space-y-4 w-full">
          <Skeleton className='w-full h-6' />
          <Skeleton className='w-full h-6' />
        </div>
        :
        <div>
          <h3 className="text-lg font-semibold">{name}</h3>
          <p className="text-sm text-gray-600">
            <strong>Investment requirement:</strong> {dollarShortener(minimum)}
            {maximum ? (
              <span>
                <i className='text-sm'>(min)</i> <span className='mx-1'>-</span>{' '}
                {dollarShortener(maximum)}
                <i className='text-sm'>(max)</i>
              </span>
            ) : (
              <span>+</span>
            )}
          </p>
        </div>
      }
    </div>
  )
}

export default CommitmentOpportunity
