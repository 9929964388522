import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react'
import type { ColumnDef, RowData } from '@tanstack/react-table'
import { Input } from 'components/ui/Input'
import { CurrencyInput } from 'components/ui/CurrencyInput'
import { dollarFormat } from 'constants/DollarsFormat'

interface Props {
  fields: {
    id: string
    type: string
  }[]
}

interface EditableInputProps {
  inputRef: RefObject<HTMLInputElement>
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
}

interface EditableCurrencyProps {
  inputRef: RefObject<HTMLInputElement>
  value: number
  setValue: (val: string) => void
  onBlur: () => void
}

interface EditableCellProps {
  value: string | number
  setShowInput: (show: boolean) => void
}

const EditableInput = ({ inputRef, value, onChange, onBlur }: EditableInputProps) => (
  <Input ref={inputRef} inputSize='sm' value={value} onChange={onChange} onBlur={onBlur} />
)

const EditableCurrency = ({ inputRef, value, setValue, onBlur }: EditableCurrencyProps) => (
  <CurrencyInput
    ref={inputRef}
    value={value}
    className='h-10 m-0 px-2'
    onValueChange={val => {
      if (!val) return
      setValue(val)
    }}
    onBlur={onBlur}
  />
)

const EditablePercentage = ({ inputRef, value, onChange, onBlur }: EditableInputProps) => (
  <Input
    ref={inputRef}
    type='number'
    min='0'
    max='100'
    step='1'
    inputSize='sm'
    value={Math.round(+value)}
    onChange={e => {
      const { value } = e.target
      const rounded = Math.round(+value)
      onChange({ ...e, target: { ...e.target, value: rounded.toString() } })
    }}
    onBlur={onBlur}
  />
)

const EditableCell = ({ value, setShowInput }: EditableCellProps) => {
  const getDisplayValue = (value: string | number | null) => {
    if (value === null || value === undefined || value === '') {
      return '-'
    }
    return value
  }

  return (
    <button
      className='text-left w-full min-h-[24px]'
      type='button'
      onClick={() => setShowInput(true)}
    >
      {getDisplayValue(value)}
    </button>
  )
}

export function getEditableColumn<T>(props: Props) {
  const { fields } = props

  const defaultColumn: Partial<ColumnDef<T>> = {
    cell: ({ getValue, row, column, table }) => {
      const initialValue = getValue()

      const inputRef = useRef<HTMLInputElement>(null)

      const [showInput, setShowInput] = useState<boolean>(false)
      const [value, setValue] = useState<string | number>(() => {
        if (initialValue === '-') return '' as string | number
        return (initialValue ?? '') as string | number
      })

      // Toggle input tag visibility
      useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            onBlur()
            setShowInput(false)
          }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }, [value])

      useEffect(() => {
        if (showInput && inputRef.current) inputRef.current.focus()
      }, [showInput])

      const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setValue(value)
      }

      const onBlur = () => {
        const { index } = row
        const { id } = column
        table.options.meta?.updateData?.(index, id, value)
      }

      const matchingField = fields.find(field => field.id === column.id)

      if (!matchingField) return value

      switch (matchingField.type) {
        case 'currency':
          if (showInput) {
            return (
              <EditableCurrency
                inputRef={inputRef}
                value={+value}
                setValue={setValue}
                onBlur={onBlur}
              />
            )
          }
          return <EditableCell value={dollarFormat(value, true)} setShowInput={setShowInput} />

        case 'input':
          if (showInput) {
            return (
              <EditableInput
                inputRef={inputRef}
                value={value as string}
                onChange={onChange}
                onBlur={onBlur}
              />
            )
          }
          return <EditableCell value={value as string} setShowInput={setShowInput} />

        case 'percentage':
          if (showInput) {
            return (
              <EditablePercentage
                inputRef={inputRef}
                value={value as string}
                onChange={onChange}
                onBlur={onBlur}
              />
            )
          }
          return <EditableCell value={Math.round(+value)} setShowInput={setShowInput} />

        default:
          return value
      }
    }
  }

  return defaultColumn
}

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData?: (rowIndex: number, columnId: string, value: unknown) => void
  }
}
