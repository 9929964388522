import { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { Button } from 'components/ui/Button'
import { useGetCommitmentsByOpportunityId, useGetOpportunity } from 'hooks/api/useQuery.hooks'

interface Props {
  id: string
}

const CSV_HEADERS = [
  { label: 'User_ID', key: 'userId' },
  { label: 'Name', key: 'name' },
  { label: 'Organization', key: 'organization' },
  { label: 'Entity_ID', key: 'entityId' },
  { label: 'Entity', key: 'entity' },
  { label: 'Fund Investment', key: 'fundInvestment' },
  { label: 'Platform', key: 'platform' },
  { label: 'Requested Amount', key: 'amountRequested' },
  { label: 'Distributed Amount', key: 'distributedAmount' },
  { label: 'Fee years', key: 'feeYears' },
  { label: 'Final Invested', key: 'finalInvested' },
  { label: 'Final Fees', key: 'finalFees' },
  { label: 'Final Total', key: 'finalTotal' },
  { label: 'Notified', key: 'notified' }
]

export function CommitmentsCSVExport({ id: opportunityId }: Props) {
  const { data: opportunity } = useGetOpportunity(opportunityId)
  const { data: commitments } = useGetCommitmentsByOpportunityId(+opportunityId)

  const csvData = useMemo(() => {
    if (!commitments) return []
    return commitments.map(commitment => ({
      userId: commitment.user_id,
      name: commitment.name,
      organization: commitment.organization.name,
      entityId: commitment.entity.id,
      entity: commitment.entity.name,
      fundInvestment:
        commitment.vintage_investment_amount ?? commitment.collection_investment_amount,
      platform: commitment.platform,
      amountRequested: commitment.amount_requested,
      distributedAmount: commitment.distributed_amount,
      feeYears: commitment.fee_years,
      finalInvested: commitment.final_amount_invested,
      finalFees: commitment.final_amount_fee,
      finalTotal: commitment.final_amount_total,
      notified: commitment.client_notified_at
    }))
  }, [commitments])

  return (
    <div className='flex mt-2'>
      <Button size='sm' variant='outline' className='ml-auto' asChild>
        <CSVLink
          headers={CSV_HEADERS}
          data={csvData}
          filename={`${opportunity?.name}-commitments.csv`}
        >
          Export CSV
        </CSVLink>
      </Button>
    </div>
  )
}
