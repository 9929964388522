// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_navWrapper__DF2wE {
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 0.5rem;
  transition-duration: 250ms;
  display: none;
  cursor: pointer; }

@media (min-width: 640px) {
  .header_navWrapper__DF2wE {
    display: flex; } }

@media (min-width: 768px) {
  .header_navWrapper__DF2wE {
    padding: 0 1rem; } }

@media (min-width: 1024px) {
  .header_navWrapper__DF2wE {
    padding: 0 1.5rem; } }

.header_navDropdownDisabled__Wzf7O {
  pointer-events: none;
  cursor: default;
  color: 'red'; }

.header_navItem__rhFqb {
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase; }

.header_navArrow__dWT2B {
  stroke-width: 0.5px;
  transition-duration: 250ms; }

.header_navWrapper__DF2wE:hover {
  background: black; }

.header_navWrapper__DF2wE:hover .header_dropdown__8nyBS {
  max-height: 600px;
  background: black; }

.header_navWrapper__DF2wE:hover .header_navArrow__dWT2B {
  transform: rotate(180deg); }

.header_dropdown__8nyBS {
  position: absolute;
  background: #161616;
  transform: translateY(100%);
  bottom: 0;
  right: 0;
  max-width: 200px;
  transition-duration: 250ms;
  max-height: 0px;
  overflow: hidden;
  color: white;
  min-width: 180px;
  justify-content: center;
  display: flex; }

.header_dropdown__8nyBS:hover {
  background: black; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/header.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,0BAA0B;EAC1B,aAAa;EACb,eAAe,EAAA;;AAEf;EATF;IAUI,aAAa,EAAA,EAUhB;;AAPC;EAbF;IAcI,eAAe,EAAA,EAMlB;;AAHC;EAjBF;IAkBI,iBAAiB,EAAA,EAEpB;;AAED;EACE,oBAAoB;EACpB,eAAe;EACf,YAAY,EAAA;;AAGd;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,yBAAyB,EAAA;;AAG3B;EACE,mBAAmB;EACnB,0BAA0B,EAAA;;AAG5B;EACE,iBAAiB,EAAA;;AAGnB;EACE,iBAAiB;EACjB,iBAAiB,EAAA;;AAGnB;EACE,yBAAyB,EAAA;;AAG3B;EACE,kBAAkB;EAClB,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;EACT,QAAQ;EACR,gBAAgB;EAChB,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,aAAa,EAAA;;AAGf;EACE,iBAAiB,EAAA","sourcesContent":[".navWrapper {\n  position: relative;\n  height: 100%;\n  align-items: center;\n  padding: 0 0.5rem;\n  transition-duration: 250ms;\n  display: none;\n  cursor: pointer;\n\n  @screen sm {\n    display: flex;\n  }\n\n  @screen md {\n    padding: 0 1rem;\n  }\n\n  @screen lg {\n    padding: 0 1.5rem;\n  }\n}\n\n.navDropdownDisabled {\n  pointer-events: none;\n  cursor: default;\n  color: 'red';\n}\n\n.navItem {\n  color: white;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  text-transform: uppercase;\n}\n\n.navArrow {\n  stroke-width: 0.5px;\n  transition-duration: 250ms;\n}\n\n.navWrapper:hover {\n  background: black;\n}\n\n.navWrapper:hover .dropdown {\n  max-height: 600px;\n  background: black;\n}\n\n.navWrapper:hover .navArrow {\n  transform: rotate(180deg);\n}\n\n.dropdown {\n  position: absolute;\n  background: #161616;\n  transform: translateY(100%);\n  bottom: 0;\n  right: 0;\n  max-width: 200px;\n  transition-duration: 250ms;\n  max-height: 0px;\n  overflow: hidden;\n  color: white;\n  min-width: 180px;\n  justify-content: center;\n  display: flex;\n}\n\n.dropdown:hover {\n  background: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navWrapper": `header_navWrapper__DF2wE`,
	"navDropdownDisabled": `header_navDropdownDisabled__Wzf7O`,
	"navItem": `header_navItem__rhFqb`,
	"navArrow": `header_navArrow__dWT2B`,
	"dropdown": `header_dropdown__8nyBS`
};
export default ___CSS_LOADER_EXPORT___;
