import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { X } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { useEditInvestmentInfoMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { CreatableSelect } from 'components/ui/CreatableSelect'
import { DataStore, Opportunity, Property } from 'services/DataStore'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
}

interface SelectableOption {
  label: string
  value: number
}

const investmentInfoSchema = z.object({
  dealshare_stage_property_id: z.number().optional(),
  dealshare_stage_rollup_property_id: z.number().optional(),
  dealshare_economic_sector_property_id: z.number().optional(),
  tag: z.string()
})
type InvestmentInfoSchemaSchema = z.infer<typeof investmentInfoSchema>

function extractOptions(r: any[], property_type: string) {
  return r
    .filter((o: { property_type: string }) => o.property_type === property_type)
    .map((s: { name: string; id: number }) => {
      return { label: s.name, value: s.id }
    })
}

export function EditInvestmentInfoDialog({ open, toggleOpen, opportunity }: Props) {
  const isCoinvest = opportunity?.opportunity_type === Opportunity.TYPE_DEALSHARE
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const form = useForm<InvestmentInfoSchemaSchema>({
    resolver: zodResolver(investmentInfoSchema),
    defaultValues: {
      dealshare_stage_property_id: isCoinvest ? opportunity.dealshare_stage.id : undefined,
      dealshare_stage_rollup_property_id: isCoinvest ? opportunity.dealshare_stage_rollup.id : undefined,
      dealshare_economic_sector_property_id: isCoinvest ? opportunity.dealshare_economic_sector.id : undefined,
      tag: ''
    }
  })

  const [tagList, setTagList] = useState<string[]>(opportunity.tag_list)
  const [stageOptions, setStageOptions] = useState<SelectableOption[]>([])
  const [stageRollupOptions, setStageRollupOptions] = useState<SelectableOption[]>([])
  const [economicSectorOptions, setEconomicSectorOptions] = useState<SelectableOption[]>([])

  const { mutateAsync, isLoading } = useEditInvestmentInfoMutate()

  const handlePropertyChange = async (property_type: string, selectedOption: SelectableOption, actionMeta: { action: string }) => {
    let id

    if (actionMeta && actionMeta.action === 'create-option') {
      const newProperty = await DataStore.create('properties', {
        name: selectedOption.label,
        property_type: property_type
      })
      const newOption = { label: newProperty.name, value: newProperty.id }
      if (property_type === Property.TYPE_DEALSHARE_STAGE ) setStageOptions([...stageOptions, newOption])
      if (property_type === Property.TYPE_DEALSHARE_STAGE_ROLLUP ) setStageRollupOptions([...stageRollupOptions, newOption])
      if (property_type === Property.TYPE_ECONOMIC_SECTOR ) setEconomicSectorOptions([...economicSectorOptions, newOption])
      id = newOption.value
    } else {
      id = selectedOption.value
    }

    form.setValue(property_type as keyof InvestmentInfoSchemaSchema, id)
  }

  const onSubmit: SubmitHandler<InvestmentInfoSchemaSchema> = values => {
    const { tag, ...rest } = values
    mutateAsync(
      {
        id: opportunity.id,
        ...rest,
        tag_list: tagList.join(',')
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Investment info updated'
          })
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  useEffect(() => {
    DataStore.findAll('properties', {}, { force: true }).then(r => {
      setStageOptions(extractOptions(r, Property.TYPE_DEALSHARE_STAGE))
      setStageRollupOptions(extractOptions(r, Property.TYPE_DEALSHARE_STAGE_ROLLUP))
      setEconomicSectorOptions(extractOptions(r, Property.TYPE_ECONOMIC_SECTOR))
    })
  }, [])

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px] max-h-[85%] overflow-y-auto'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit Investment Info</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className='space-y-4'>
              {isCoinvest && <FormField
                control={form.control}
                name='dealshare_stage_property_id'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Stage</FormLabel>
                      <FormControl>
                        <CreatableSelect
                          {...field}
                          options={stageOptions}
                          value={stageOptions.find(o => o.value === field.value)}
                          onChange={(selectedOption, actionMeta) =>
                            handlePropertyChange(
                              Property.TYPE_DEALSHARE_STAGE,
                              selectedOption as SelectableOption,
                              actionMeta as { action: string }
                            )
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />}
              {isCoinvest && <FormField
                control={form.control}
                name='dealshare_stage_rollup_property_id'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Stage Rollup</FormLabel>
                      <FormControl>
                        <CreatableSelect
                          {...field}
                          options={stageRollupOptions}
                          value={stageRollupOptions.find(o => o.value === field.value)}
                          onChange={(selectedOption, actionMeta) =>
                            handlePropertyChange(
                              Property.TYPE_DEALSHARE_STAGE_ROLLUP,
                              selectedOption as SelectableOption,
                              actionMeta as { action: string }
                            )
                          }

                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />}
              {isCoinvest && <FormField
                control={form.control}
                name='dealshare_economic_sector_property_id'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Sector</FormLabel>
                      <FormControl>
                        <CreatableSelect
                          {...field}
                          options={economicSectorOptions}
                          value={economicSectorOptions.find(o => o.value === field.value)}
                          onChange={(selectedOption, actionMeta) =>
                            handlePropertyChange(
                              Property.TYPE_ECONOMIC_SECTOR,
                              selectedOption as SelectableOption,
                              actionMeta as { action: string }
                            )
                          }

                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />}
              <FormField
                control={form.control}
                name='tag'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Add Tags</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='Press enter to add a new tag'
                          onKeyDown={e => {
                            if (e.key === 'Enter') e.preventDefault()
                          }}
                          onKeyUp={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              if (tagList.includes(field.value)) return
                              else setTagList([...tagList, field.value])
                              field.onChange('')
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                      <ul className='flex items-center space-x-2'>
                        {tagList.map(tag => (
                          <li
                            key={tag}
                            className='flex items-center border border-[#10273A] rounded-sm bg-[#06283C12] px-2 py-0.5 text-sm'
                          >
                            {tag}
                            <button
                              type='button'
                              className='ml-2'
                              onClick={() => {
                                if (tagList.includes(tag))
                                  setTagList(prev => prev.filter(st => st !== tag))
                              }}
                            >
                              <X className='w-3 h-3' />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
