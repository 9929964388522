import { useState } from 'react'
import { Trash2 } from 'lucide-react'
import { Opportunity } from 'services/DataStore'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { DealsSearchQueryParams, OpportunityResponse } from 'types/api-types'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import OpportunitySelector from 'components/fields/OpportunitySelector'
import CommitmentOpportunity from './CommitmentOpportunity'
import { Skeleton } from 'components/ui/Skeleton'
import { Button } from 'components/ui/Button'

interface CommitmentOpportunitySelectorCardProps {
  defaultOpportunityId: number | undefined
  onSelect: (opportunity: OpportunityResponse) => void
  onClear: () => void
  user: any
  minimum: number
  maximum?: number
}

const CommitmentOpportunitySelectorCard: React.FC<CommitmentOpportunitySelectorCardProps> = ({
  defaultOpportunityId = '',
  onSelect,
  onClear,
  minimum,
  maximum,
  user
}) => {
  const [oppId, setOppId] = useState(`${defaultOpportunityId}`)
  const { data: opp, isLoading } = useGetOpportunity(oppId)
  const opportunity = Object.assign(new Opportunity(), opp)

  return (
    <Card className='h-full'>
      <CardHeader className='pb-0'>
        <CardTitle>
          <div className="flex justify-between items-center">
            <span>Opportunity</span>
            { !defaultOpportunityId && oppId &&
              <Button
                type='button'
                size='icon'
                variant='ghost'
                onClick={ () => {
                setOppId('')
                onClear()
              }}>
                <Trash2 className='w-4 h-4 text-archived' />
              </Button>
            }
          </div>
        </CardTitle>
        <hr className="border-t border-gray-200 !mt-4" />
      </CardHeader>
      <CardContent>
        {
          !oppId ? <OpportunitySelector
            filters={{
              no_commits_for_user: user?.id,
              skip_platform: user?.platform === 'champion' ? 'vintage' : 'collection',
            } as DealsSearchQueryParams}
            onSelect={(item: { id: number }) => {
              onSelect(item as OpportunityResponse)
              setOppId(`${item.id}`)
            }}
            className='mt-4'
          />
          :
          <CommitmentOpportunity
            name={opportunity?.name}
            logo={opportunity?.logo?.url as string}
            minimum={minimum}
            maximum={maximum}
            isLoading={isLoading}
          />
        }
      </CardContent>
    </Card>
  )
}

export default CommitmentOpportunitySelectorCard
