import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import type { RowSelectionState } from '@tanstack/react-table'
import { useGetCommitmentsByOpportunityId, useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { CommitmentsResponse } from 'types/api-types'
import { useTable, useTableSort } from 'hooks/useTable.hooks'
import {
  useSendCommitmentsNotificationsMutate,
  useUpdateCommitmentById
} from 'hooks/api/useMutation.hooks'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useToast } from 'hooks/userToast.hooks'
import { Button } from 'components/ui/Button'
import { commitmentKeyFactory } from 'helpers/api/factories/userKey'
import { EditableTable } from '../EditableTable'
import { getEditableColumn } from '../EditableColumn'
import { DeleteCommitmentDialog } from './DeleteCommitmentDialog'
import { getColumn } from './Columns'
import { ManageClientDetailsDialog } from 'components/modals/manageDetails/ManageClientDetailsDialog'

interface Props {
  id: string
}

const DEAL_TYPES = {
  all: 'all',
  mvp: 'mvp',
  champion: 'champion'
}
const EDITABLE_FIELDS = [
  { id: 'amount_requested', type: 'currency' },
  { id: 'fee_years', type: 'input' },
  { id: 'final_amount_invested', type: 'currency' },
  { id: 'final_amount_fee', type: 'currency' },
  { id: 'final_amount_total', type: 'currency' },
  { id: 'distributed_amount', type: 'currency' },
  { id: 'carry_share', type: 'percentage' }
]
const SELECT_LIST = [
  { label: 'All', value: DEAL_TYPES.all },
  { label: 'MVP', value: DEAL_TYPES.mvp },
  { label: 'Champion', value: DEAL_TYPES.champion }
]

export function CommitmentsTable({ id: opportunityId }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { user_id } = useParams<{ user_id: string }>()

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(+user_id || undefined)

  const { data: opportunity } = useGetOpportunity(opportunityId)
  const {
    data: commitments,
    isLoading,
    isFetching
  } = useGetCommitmentsByOpportunityId(+opportunityId)

  const { mutate: sendCommitmentsNotifications, isLoading: isSendingNotification } =
    useSendCommitmentsNotificationsMutate()
  const { mutate: updateCommitment } = useUpdateCommitmentById()

  const [selectedRow, setSelectedRow] = useState<string>('')
  const [dealType, setDealType] = useState<string>(DEAL_TYPES.all)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const filteredCommitments = useMemo(() => {
    if (!commitments) return []
    if (dealType === DEAL_TYPES.mvp)
      return commitments.filter(com => com.platform === DEAL_TYPES.mvp)

    if (dealType === DEAL_TYPES.champion)
      return commitments.filter(com => com.platform === DEAL_TYPES.champion)

    return commitments
  }, [commitments, dealType])

  const validCommitmentsCount = useMemo(() => {
    if (!commitments) return 0
    return commitments.filter(commitment => +commitment.final_amount_invested > 0).length
  }, [commitments])

  const { sorting, onSortingChange } = useTableSort()

  const table = useTable<CommitmentsResponse>({
    columns: getColumn({ onNameClick: setSelectedUserId }),
    data: filteredCommitments,
    isLoading: isLoading || isFetching,
    defaultColumn: getEditableColumn<CommitmentsResponse>({
      fields: EDITABLE_FIELDS
    }),
    state: { rowSelection, sorting },
    onRowSelectionChange: setRowSelection,
    onSortingChange,
    // manualSorting: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        const foundCommitment = filteredCommitments[rowIndex]
        const updatedCommitment = {
          ...foundCommitment,
          [columnId]: value
        }
        handleUpdateCommitment(updatedCommitment)
      }
    }
  })

  const sendNotifications = () => {
    const selectedCommitmentIds = Object.keys(rowSelection)
      .map(key => +key)
      .map(index => filteredCommitments[index].id)

    sendCommitmentsNotifications(selectedCommitmentIds, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          commitmentKeyFactory.commitmentsByOpportunityId(+opportunityId)
        )
        toast({
          variant: 'success',
          description: 'Email notification sent successfully',
          duration: 3000
        })
        setRowSelection({})
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  const handleUpdateCommitment = (commitment: CommitmentsResponse) => {
    queryClient.setQueriesData<CommitmentsResponse[]>(
      commitmentKeyFactory.commitmentsByOpportunityId(+opportunityId),
      oldData => {
        if (!oldData) return undefined
        return oldData.map(d => {
          if (d.id === commitment.id) return commitment
          return d
        })
      }
    )
    updateCommitment(
      { id: commitment.id, payload: commitment },
      {
        onError: (error, data, context) => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
          // queryClient.setQueryData<CommitmentsResponse[]>(
          //   commitmentKeyFactory.commitmentsByOpportunityId(+opportunityId),
          //   queryClient.getQueryState(commitmentKeyFactory.commitmentsByOpportunityId(+opportunityId))
          //   context
          // )
        }
      }
    )
  }

  const handleDeleteCommitment = () => {
    const rows = Object.keys(rowSelection)
    if (!rows.length) return
    if (rows.length > 1) return alert('Cannot select multiple rows')

    const row = rows[0]
    const foundCommitmentId = filteredCommitments[+row].id

    setSelectedRow(`${foundCommitmentId}`)
  }

  const isRowSelected = !!Object.keys(rowSelection).length
  const isMultiRowSelected = Object.keys(rowSelection).length > 1

  return (
    <div>
      <div className='flex items-center py-4'>
        <h2 className='text-2xl m-0 w-full'>
          Commitments for {opportunity?.name} ({validCommitmentsCount})
        </h2>

        <div className='flex space-x-4'>
          <Select
            defaultValue={DEAL_TYPES.all}
            onValueChange={val => {
              setDealType(val)
            }}
          >
            <SelectTrigger className='flex-1 w-full max-w-xs m-0 border-slate-900'>
              <SelectValue placeholder='Select page limit' />
            </SelectTrigger>
            <SelectContent className='z-[60] bg-white'>
              {SELECT_LIST.map(({ label, value }) => (
                <SelectItem key={label} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <LoadingButton
            variant='outline'
            size='sm'
            disabled={!isRowSelected}
            loading={isSendingNotification}
            onClick={sendNotifications}
          >
            Send email notification to selected members
          </LoadingButton>
          <Button
            variant='destructive'
            size='sm'
            disabled={!isRowSelected || isMultiRowSelected}
            onClick={handleDeleteCommitment}
          >
            Delete Commitment
          </Button>
        </div>
      </div>

      <EditableTable<CommitmentsResponse>
        table={table}
        className='mt-2 md:mt-4 overflow-y-auto max-h-[60vh]'
        isClickableRow
      />

      <DeleteCommitmentDialog
        opportunityId={+opportunityId}
        id={+selectedRow}
        open={!!selectedRow}
        onClose={() => setSelectedRow('')}
        onUnSelectRow={() => setRowSelection({})}
      />

      <ManageClientDetailsDialog
        memberId={selectedUserId}
        open={selectedUserId !== undefined}
        toggleOpen={status => {
          if (!status) setSelectedUserId(undefined)
        }}
      />
    </div>
  )
}
