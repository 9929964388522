import { useMemo, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { dollarFormat } from 'constants/DollarsFormat'
import { Opportunity } from 'services/DataStore'
import { convertDealType } from 'services/utils'
import { useGetCommitmentsByOpportunityId, useGetOpportunity } from 'hooks/api/useQuery.hooks'
import type { OpportunityResponse, CommitmentsResponse } from 'types/api-types'
import { DealLoader } from './layouts/Loader'
import { Button } from 'components/ui/Button'
import { EditInvestmentInfoDialog } from 'components/core/v2/deal/dialogs/EditInvestmentInfoDialog'
import { Badge } from "components/ui/Badge"

interface Props {
  id: string
}
interface InnerProps {
  opportunity: OpportunityResponse
  commitments: CommitmentsResponse[] | undefined
}

export function InvestmentInfo({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)
  const { data: commitments } = useGetCommitmentsByOpportunityId(opportunity?.id)
  const [openInvestmentInfoForm , setOpenInvestmentInfoForm] = useState<boolean>(false)

  if (!opportunity || !commitments) return <DealLoader.Card />

  const opportunityType = convertDealType(opportunity.opportunity_type)
  return (
    <>
    <Card className='h-full'>
      <CardHeader className='flex flex-row items-center p-5 space-y-0'>
        <CardTitle>{opportunityType} Info</CardTitle>
        <Button
          type='button'
          variant='ghost'
          size='sm'
          disabled={!opportunity}
          className='ml-auto text-success'
          onClick={() => setOpenInvestmentInfoForm(true)}
        >
          Edit
        </Button>
      </CardHeader>

      <CardContent className='p-5 pt-0 '>
        {opportunity.opportunity_type === Opportunity.TYPE_VINTAGE && (
          <MVPFund opportunity={opportunity} commitments={commitments} />
        )}
        {opportunity.opportunity_type === Opportunity.TYPE_DEALSHARE && (
          <CoInvest opportunity={opportunity} commitments={commitments} />
        )}
        <TagList tags={opportunity.tag_list} />
      </CardContent>
    </Card>
    <EditInvestmentInfoDialog
      opportunity={opportunity}
      open={openInvestmentInfoForm}
      toggleOpen={open => {
        setOpenInvestmentInfoForm(open)
      }}
    />
    </>
  )
}

function MVPFund({ opportunity, commitments }: InnerProps) {
  const fund = useMemo(() => {
    if (!commitments) return
    return {
      total: commitments
        .map(commitment => +commitment.final_amount_invested)
        .reduce((prev, curr) => (curr += prev), 0)
    }
  }, [commitments])

  return (
    <ul className='flex flex-col space-y-4'>
      <li>
        <b>Current MVP Total:</b> <span>{dollarFormat(fund?.total ?? 0)}</span>
      </li>
      <li>
        <b>Anticipated Fund Size:</b>{' '}
        <span>{dollarFormat(opportunity.vintage_anticipated_amount)}</span>
      </li>
      <li>
        <b>{opportunity.associated_collection?.name} Commitments:</b>{' '}
        <span>{dollarFormat(opportunity.associated_collection?.commitments)}</span>
      </li>
    </ul>
  )
}

function CoInvest({ opportunity }: InnerProps) {
  return (
    <div className='flex space-x-8'>
      <ul className='flex flex-col space-y-4'>
        <li className='m-0'>
          <b>Stage:</b> <span>{opportunity.dealshare_stage?.name}</span>
        </li>
        <li className='m-0'>
          <b>Stage Rollup:</b> <span>{opportunity.dealshare_stage_rollup?.name}</span>
        </li>
        <li className='m-0'>
          <b>Sector:</b> <span>{opportunity.dealshare_economic_sector?.name}</span>
        </li>
      </ul>
    </div>
  )
}

function TagList({ tags }: { tags: string[] }) {
  return (
    <ul className='flex flex-wrap gap-2 mt-4'>
      <li className='m-0'>
        <b>Tags:</b>
      </li>
      {
        tags.length > 0 ?
          tags.map(tag => (
            <Badge key={`tag-${tag}`}>
              {tag}
            </Badge>
          ))
          :
          '--'
        }
    </ul>
  )
}
