import { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { RefreshCw } from 'lucide-react'
import z from 'zod'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import type { OpportunityResponse } from 'types/api-types'
import configuration from 'services/config'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'
import { Input } from 'components/ui/Input'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { useToast } from 'hooks/userToast.hooks'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
}

function logoUrl(url: string | ArrayBuffer | null | undefined) {
  console.info('logourl, editlogodialog', { url })
  if (!url) return ''
  if (typeof url === 'string' && url.indexOf('data:') > -1) return url

  return String(url)
}

const hexColorSchema = z.string().min(6, { message: 'Must be a valid color' })

const MAX_LOGO_SIZE = 2 * 1024 * 1024 // 2MB

export function EditLogoDialog({ opportunity, open, toggleOpen }: Props) {
  const logoInput = useRef<HTMLInputElement>(null)
  const colorInput = useRef<HTMLInputElement>(null)

  const queryClient = useQueryClient()
  const { toast } = useToast()

  const [logo, setLogo] = useState<{ url: string | ArrayBuffer | null | undefined; name: string }>({
    url: opportunity.logo.url,
    name: ''
  })
  const [showColorInput, setShowColorInput] = useState<boolean>(false)
  const [color, setColor] = useState<string>(opportunity.background_color)

  const { mutateAsync, isLoading } = useUpdateOpportunityMutate()

  const handleLogoClick = () => {
    logoInput.current?.click()
  }

  const handleLogoChange: ChangeEventHandler<HTMLInputElement> = e => {
    const file = e.target.files && e.target.files[0]

    if (!file) return
    if (file.size > MAX_LOGO_SIZE) {
      alert(`File size should be less than ${MAX_LOGO_SIZE / (1024 * 1024)}MB`)
      return
    }
    if (!(file instanceof Blob)) {
      alert('Invalid file')
      return
    }

    const reader = new FileReader()
    reader.onloadend = event => {
      setLogo({
        url: event.target?.result,
        name: file.name
      })
    }

    reader.onerror = () => {
      alert('Could not read file')
    }

    reader.readAsDataURL(file)
  }

  const handleSubmit = async () => {
    const validate = await hexColorSchema.safeParseAsync(color)
    if (!validate.success) {
      return toast({
        variant: 'destructive',
        description: 'Must be a valid color',
        duration: 2000
      })
    }

    await mutateAsync(
      { id: opportunity.id, background_color: color, logo: { url: logo.url, name: logo.name } },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toast({
            variant: 'success',
            description: 'Logo / color have been updated'
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  useEffect(() => {
    if (showColorInput) colorInput.current?.focus()
  }, [showColorInput])

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[624px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit Logo</DialogTitle>
        </DialogHeader>

        <div className='mt-2'>
          <div className='h-[238px] rounded-xl p-4' style={{ backgroundColor: color }}>
            {logo.url && (
              <img
                src={logoUrl(logo.url)}
                alt={opportunity.name}
                onError={({ currentTarget }) => {
                  currentTarget.className = 'w-full rounded-lg invisible'
                }}
                className='w-full h-full object-contain'
              />
            )}
          </div>

          <div className='flex flex-col items-stretch sm:flex-row mt-4 space-y-2 sm:space-x-2 sm:space-y-0'>
            <div className='flex-1 flex flex-col text-[#236185] border border-[#236185] rounded-md h-full max-h-[74px]'>
              <span className='mt-2 ml-2 text-sm font-medium'>Change Background Color</span>
              {showColorInput ? (
                <Input
                  ref={colorInput}
                  placeholder='e.g. #FFFFFF'
                  className='h-full mt-1'
                  value={color}
                  onChange={e => setColor(e.target.value)}
                  onBlur={() => setShowColorInput(false)}
                />
              ) : (
                <Button
                  type='button'
                  variant='ghost'
                  onClick={() => setShowColorInput(true)}
                  className='h-0 mt-1 justify-start'
                >
                  {color}
                </Button>
              )}
            </div>

            <div className='flex-1'>
              <input
                type='file'
                className='hidden'
                accept='.jpg,.jpeg,.png,.svg'
                onChange={handleLogoChange}
                ref={logoInput}
              />
              <Button
                variant='outline'
                type='button'
                className='w-full h-full space-x-2 max-h-[74px] text-[#236185] border-[#236185]'
                onClick={handleLogoClick}
              >
                <RefreshCw className='w-5 h-5' />
                <span>Replace Image</span>
              </Button>
            </div>
          </div>
        </div>

        <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='flex-1'
            onClick={() => toggleOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            type='submit'
            loading={isLoading}
            size='sm'
            className='flex-1 bg-success'
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
