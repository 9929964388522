// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes appear {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.widget-align-right {
  @media screen and (max-width: 760px) {
    margin-bottom: 50px;
  }
}

.delay {
  animation-duration: 3s;
  animation-name: appear;
  animation-iteration-count: 1;
  animation-delay: 4s;
  animation-fill-mode: both;
}
`, "",{"version":3,"sources":["webpack://./src/assets/scss/homepage.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,4BAA4B;EAC5B,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":["@keyframes appear {\n  0% {\n    visibility: hidden;\n  }\n  100% {\n    visibility: visible;\n  }\n}\n\n.widget-align-right {\n  @media screen and (max-width: 760px) {\n    margin-bottom: 50px;\n  }\n}\n\n.delay {\n  animation-duration: 3s;\n  animation-name: appear;\n  animation-iteration-count: 1;\n  animation-delay: 4s;\n  animation-fill-mode: both;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
