import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/Dialog'
import { CommitmentsForm } from 'components/core/v2/commitments/CommitmentsForm'
import type { CommitmentsResponse, UserInfo } from 'types/api-types'

// TODO: fix types and props when refactoring commitments modal
interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  user: UserInfo | undefined
  commitment: CommitmentsResponse | undefined
}

export function CommitmentsDialog({
  open,
  toggleOpen,
  user,
  commitment,
}: Props) {
  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-5xl'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>{commitment?.id ? 'Update' : 'Create'} Commitment</DialogTitle>
        </DialogHeader>

        <CommitmentsForm
          toggleOpen={toggleOpen}
          commitment={commitment}
          userId={user?.id}
        />
      </DialogContent>
    </Dialog>
  )
}
