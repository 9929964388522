import { useQueryClient } from '@tanstack/react-query'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/Dialog'
import { Skeleton } from 'components/ui/Skeleton'
import { useMemberQuery } from 'hooks/api/useQuery.hooks'
import { ManageDetailsDialogProvider } from 'contexts/ManageDetailsDialog.context'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import type { UserInfo } from 'types/api-types'
import { EditUser } from './components/EditUser'
import { EditOrganization } from './components/EditOrganization'
import { Commitments } from './components/Commitments'
import { MvpLogo } from 'components/icons/Mvp'
import { ChampionIcon } from 'components/icons/Champion'

function DialogSkeleton() {
  return (
    <div className='flex flex-col space-y-2'>
      <Skeleton className='h-10 w-full' />
      <Skeleton className='h-5 w-full' />
      <Skeleton className='h-5 w-full' />
      <Skeleton className='h-5 w-full' />
    </div>
  )
}

interface Props {
  memberId: number | undefined
  open: boolean
  toggleOpen: (open: boolean) => void
}

export function ManageClientDetailsDialog({ memberId, open, toggleOpen }: Props) {
  const queryClient = useQueryClient()

  const { data: currentMember, isLoading, isFetching } = useMemberQuery(memberId)

  const userUpdatedHandler = async (user: UserInfo) => {
    if (!currentMember) return
    await queryClient.invalidateQueries({
      queryKey: userKeyFactory.member(currentMember.id),
      refetchType: 'all'
    })
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='rounded-md max-w-5xl overflow-y-auto max-h-full'>
        <ManageDetailsDialogProvider
          state={{ user: currentMember }}
          handlers={{
            onCloseManageDetailsDialog: () => toggleOpen(false),
            onUpdateMember: userUpdatedHandler
          }}
        >
          {!currentMember || (isLoading && isFetching) ? (
            <DialogSkeleton />
          ) : (
            <>
              <DialogHeader>
                <DialogTitle className='m-0 text-2xl montserrat flex items-center space-x-2 sm:space-x-4'>
                  {currentMember.platform === 'mvp' && <MvpLogo className='w-[72px]' />}
                  {currentMember.platform === 'champion' && <ChampionIcon />}
                  <span>Client Details</span>
                </DialogTitle>
              </DialogHeader>

              <EditUser member={currentMember} isClientDetail />
              <EditOrganization />
              <Commitments />
            </>
          )}
        </ManageDetailsDialogProvider>
      </DialogContent>
    </Dialog>
  )
}
