import { useMemo } from 'react'
import { useGetCommitmentsByOpportunityId, useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { useTable } from 'hooks/useTable.hooks'
import { type CommitmentsResponse } from 'types/api-types'
import { EditableTable } from '../EditableTable'
import { totalColumns } from './TotalColumns'

interface Props {
  id: string
}

interface CommitmentCount {
  total_final_invested: number
  total_final_fees: number
  total_amount_requested: number
  total_final_total: number
}

const dealTypes = {
  all: 'all',
  mvp: 'mvp',
  champion: 'champion'
} as const

const createInitialCount =
  (fundTotal = 0) =>
  (fieldsToExclude?: string[]): CommitmentCount => {
    const initialCount: CommitmentCount = {
      total_final_invested: fundTotal,
      total_final_fees: 0,
      total_amount_requested: 0,
      total_final_total: fundTotal
    }

    fieldsToExclude?.forEach(field => {
      delete initialCount[field as keyof CommitmentCount]
    })
    return initialCount
  }

const calculateTotals = (commitment: CommitmentsResponse): CommitmentCount => {
  const invested = Number(commitment.final_amount_invested ?? 0)
  const fees = Number(commitment.final_amount_fee ?? 0)
  const requested = Number(commitment.amount_requested ?? 0)

  return {
    total_final_invested: invested,
    total_final_fees: fees,
    total_amount_requested: requested,
    total_final_total: invested + fees
  }
}

export function CommitmentsTotalTable({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)
  const { data: commitments, isLoading, isFetching } = useGetCommitmentsByOpportunityId(+id)

  const commitmentCounts = useMemo(() => {
    if (!commitments) return []

    const addCounts = (a: CommitmentCount, b: CommitmentCount): CommitmentCount => ({
      total_final_invested: a.total_final_invested + b.total_final_invested,
      total_final_fees: a.total_final_fees + b.total_final_fees,
      total_amount_requested: a.total_amount_requested + b.total_amount_requested,
      total_final_total: a.total_final_total + b.total_final_total
    })

    const fundTotal = Number(opportunity?.valuation?.fund_total ?? 0)

    const totalCounts = {
      mvp: createInitialCount()(),
      champion: createInitialCount()(),
      all: createInitialCount()(),
      fundInvestment: createInitialCount(fundTotal)(['total_amount_requested', 'total_final_fees']),
      total: createInitialCount(fundTotal)(['total_amount_requested', 'total_final_fees'])
    }

    commitments.forEach(commitment => {
      const counts = calculateTotals(commitment)

      if (commitment.platform === dealTypes.mvp) {
        totalCounts.mvp = addCounts(totalCounts.mvp, counts)
      }
      if (commitment.platform === dealTypes.champion) {
        totalCounts.champion = addCounts(totalCounts.champion, counts)
      }
      totalCounts.all = addCounts(totalCounts.all, counts)
      totalCounts.total = addCounts(totalCounts.total, counts)
    })

    return [
      { type: 'mvp', opportunity_type: opportunity?.opportunity_type, data: totalCounts.mvp },
      {
        type: 'champion',
        opportunity_type: opportunity?.opportunity_type,
        data: totalCounts.champion
      },
      { type: 'all', opportunity_type: opportunity?.opportunity_type, data: totalCounts.all },
      { type: 'fundInvestment', data: totalCounts.fundInvestment },
      { type: 'total', data: totalCounts.total }
    ]
  }, [commitments, opportunity?.opportunity_type, opportunity?.valuation?.fund_total])

  const table = useTable<any>({
    columns: totalColumns,
    data: commitmentCounts ?? [],
    isLoading: isLoading || isFetching
  })

  return (
    <div className='flex-1 mt-2'>
      <EditableTable table={table} className='overflow-y-auto max-h-[25vh]' isClickableRow />
    </div>
  )
}
