import React from 'react'
import { dealName } from '../services/utils'
import configuration from '../services/config'
import emptyImage from 'assets/images/image.svg'

function RecentOpportunity({ opportunity, fromOpportunities = false }) {
  const companyLogo = opportunity?.logo?.url || opportunity?.logo_light?.url

  return (
    <div className='rounded-lg py-4 px-6 bg-[#28353f]'>
      <a
        href={`/opportunities/${opportunity.slug}`}
        className='flex flex-col gap-5 w-full opacity-50'
      >
        <div
          className='aspect-square rounded-lg max-w-[300px] self-center w-full'
          style={{
            backgroundColor: opportunity.background_color
          }}
        >
          <img
            src={companyLogo}
            alt={opportunity.name}
            className='object-contain aspect-square w-full h-full rounded-lg'
            onError={e => (e.target.src = emptyImage)}
          />
        </div>
        <h3 className='text-white montserrat m-0 text-base font-semibold'>
          {fromOpportunities ? dealName(opportunity) : opportunity.name}
        </h3>
      </a>
    </div>
  )
}

export default RecentOpportunity
