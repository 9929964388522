import { useState } from 'react'
import { Plus, X } from 'lucide-react'
import { Dialog, DialogContent, DialogClose } from 'components/ui/Dialog'
import { Button } from 'components/ui/Button'
import EditDeal from 'components/EditDeal'
import { DEAL_FORM_TITLES } from './DealTypes'

const OPTIONS = Object.keys(DEAL_FORM_TITLES).map((key) => ({ label: DEAL_FORM_TITLES[key], value: key }))

export function NewDealDialog() {
  const [dealType, setDealType] = useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [showDealOptions, setShowDealOptions] = useState(false)
  const close = () => {
    setIsOpen(false)
    setTimeout(() => setDealType(undefined), 100) // delay to allow form to close with animation
  }

  return (
    <Dialog open={isOpen} onOpenChange={isOpen => !isOpen && close()}>
      <div className='relative'>
        <Button onClick={() => setShowDealOptions(!showDealOptions)} variant='success' size='sm' className='pl-4 pr-10'>
          New deal
          <Plus size={20} className='absolute top-1/2 right-4 -translate-y-1/2' />
        </Button>
        {showDealOptions && (
          <div className='absolute top-0 right-0 mt-12 z-10 bg-white border border-gray-200 rounded shadow-md'>
            {
              OPTIONS.map((option) => (
                <Button
                  key={option.value}
                  onClick={() => {
                    setDealType(option.value)
                    setIsOpen(true)
                    setShowDealOptions(false)
                  }}
                  variant='ghost'
                  className='w-full'
                >
                  {option.label}
                </Button>
              ))
            }
          </div>
        )}
      </div>
      {dealType && (
        <DialogContent className='bg-white max-w-[60vw] max-h-[90vh] overflow-y-auto'>
          <EditDeal
            deal={{ opportunity_type: dealType, background_color: '#FFFFFF'}}
            close={close}
            history={undefined}
          />
        </DialogContent>
      )}
    </Dialog>
  )
}
