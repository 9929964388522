import React from 'react'
import styled from 'styled-components'
import sv from '../../themes/styles'
import { cn } from 'services/utils'
import { X } from 'lucide-react'

// STYLE ##########################################################

const ContainerWrapper = styled.div`
  width: ${props => (props.show ? '100vw' : 0)};
  height: ${props => (props.show ? '100vh' : 0)};
  position: ${props => (props.absolute ? 'absolute' : 'fixed')};
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  ${sv.flexCenter};
`

const Shade = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;

  &.register-modal--champion,
  &.register-modal--mvp {
    background: rgba(0, 0, 0, 0);
  }
`

const Content = styled.div`
  background: ${props => (props.noBackground ? 'none' : sv.colors.background)};
  padding: ${props => (props.noPadding ? 0 : '1.5rem')};
  min-height: ${props => (props.minHeight ? props.minHeight : '60vh')};
  width: 90vw;
  max-width: ${props => props.maxWidth}px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 2;
  ${sv.flexColumn};
  ${sv.borderRadius};
  ${sv.box};

  &.register-modal--champion {
    background-color: ${sv.colors.championBlue};
    padding: 0px;
  }
`

// COMPONENT ##########################################################

export default function Modal({
  show,
  close,
  children,
  shadeDoesNotClose,
  small,
  medium,
  noPadding,
  noBackground,
  absolute,
  minHeight,
  className
}) {
  const handleShadeClose = e => {
    e.stopPropagation()
    !shadeDoesNotClose && close && close()
  }

  let maxWidth = 900
  if (small) {
    maxWidth = 400
  } else if (medium) {
    maxWidth = 500
  }

  return (
    <ContainerWrapper show={show} absolute={absolute} className={className}>
      <Shade onClick={handleShadeClose} className={className} />
      <Content
        className={className}
        minHeight={minHeight}
        maxWidth={maxWidth}
        noPadding={noPadding}
        noBackground={noBackground}
      >
        {children}
      </Content>
    </ContainerWrapper>
  )
}

export const DialogHeader = ({ className = '', children, showX = true, onClose, ...props }) => (
  <div className='flex items-center' {...props}>
    <div className={cn('flex flex-col space-y-1.5', className ?? '')}>{children}</div>

    <button
      onClick={onClose}
      className='ml-auto rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
    >
      {showX && (
        <>
          <X className='h-4 sm:h-5 md:h-7 w-4 sm:w-5 md:w-7' />
          <span className='sr-only'>Close</span>
        </>
      )}
    </button>
  </div>
)

export const DialogTitle = ({ children, className }) => (
  <h2 className={cn('text-lg font-semibold leading-none tracking-tight m-0', className)}>
    {children}
  </h2>
)

export const Container = ({ children, className }) => (
  <div className={cn('relative p-6', className)}>{children}</div>
)
