import type { UserInfo } from 'types/api-types'
import { EditOrganizationName } from './components/EditOrganizationName'
import { MemberList } from './components/MemberList'
import { AddNewMember } from './components/AddNewMember'
import { MergeMember } from './components/MergeMember'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/Dialog'
import { MvpLogo } from 'components/icons/Mvp'
import { ChampionIcon } from 'components/icons/Champion'
import { useUserQuery } from 'hooks/api/useQuery.hooks'

interface Props {
  currentUser: UserInfo
  open: boolean
  onToggle: (open: boolean) => void
}

export function ManageOrganizationDialog({ currentUser, open, onToggle }: Props) {
  const { data: loggedInUser } = useUserQuery()

  const isAdmin = !!loggedInUser?.is_admin

  return (
    <Dialog open={open} onOpenChange={onToggle}>
      <DialogContent className='rounded-md max-w-5xl'>
        <div>
          <DialogHeader>
            <DialogTitle className='m-0 text-xl text-2xl montserrat flex items-center space-x-2 sm:space-x-4'>
              {currentUser.platform === 'mvp' && <MvpLogo className='w-[62px] sm:w-[90px]' />}
              {currentUser.platform === 'champion' && <ChampionIcon />}
              <span>Manage Organization</span>
            </DialogTitle>
          </DialogHeader>

          <EditOrganizationName currentUser={currentUser} />

          <div className='flex flex-col space-y-2 md:flex-row md:space-y-0 mt-4 md:mt-6'>
            <h2 className='flex items-center m-0 text-lg md:text-xl font-medium flex-1'>
              Current members ({currentUser?.organization.members.length ?? 0})
            </h2>
            <div className='flex items-center space-x-2'>
              <AddNewMember currentUser={currentUser} />
              {isAdmin && <MergeMember currentUser={currentUser} />}
            </div>
          </div>

          <MemberList currentUser={currentUser} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
