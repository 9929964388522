import { useState, useEffect } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { DealsSearchQueryParams, OpportunityResponse } from 'types/api-types'
import { useGetOpportunities } from 'hooks/api/useQuery.hooks'
import { isAdmin } from 'services/authentication'
import useDebounce from 'services/useDebounce'
import { cn } from 'services/utils'

interface OpportunitySelectorProps {
  onSelect: (item: { id: number }) => void
  filters?: DealsSearchQueryParams
  className?: string
}

const OpportunitySelector: React.FC<OpportunitySelectorProps> = ({
  onSelect,
  filters = {},
  className = ''
}) => {
  const [key, setKey] = useState(0)
  const [opps, setOpps] = useState<OpportunityResponse[] | undefined>(undefined)
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const { data: opportunities }
    = useGetOpportunities(search.length > 2, {
      order_by: 'name',
      order: 'asc',
      limit: 10,
      offset: 0,
      current_page: 1,
      admin_list: false,
      search_term: debouncedSearch,
      status: isAdmin() ? ['active', 'finalized'] : ['active'],
      vintages: undefined,
      slim: true,
      ...filters
    })

  useEffect(() => {
    if (opportunities && JSON.stringify(opportunities) !== JSON.stringify(opps)) {
      setOpps(opportunities.data)
      setKey(prev => prev + 1)
    }
  }, [opportunities])

  const items = opps?.map(opp => ({id: opp.id, name: `${opp.name} [${opp?.dealshare_stage?.name}]`})) || []

  return (
    <div className={cn('mvp-search-autocomplete mvp-break-specificity', className)}>
      <ReactSearchAutocomplete
        // pass the key to force the component to re-render to take the new items
        key={key}
        // items has to be a fixed array, if not, we mess up the internal state of the component
        items={items}
        placeholder='Search for an opportunity'
        styling={{ zIndex: 100 }}
        showNoResults={false}
        onSearch={value => setSearch(value)}
        inputSearchString={search}
        autoFocus
        onSelect={(item: { id: number }) => {
          onSelect(item)
        }}
      />
    </div>
  )
}

export default OpportunitySelector
