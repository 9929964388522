import { useEffect, useRef, useState } from 'react'
import { Popover, PopoverTrigger, PopoverContent } from 'components/ui/Popover'
import { Checkbox } from 'components/ui/Checkbox'
import { Button } from 'components/ui/Button'
import { Label } from 'components/ui/Label'
import { ChevronDown } from 'lucide-react'
import { cn } from 'services/utils'

export interface MultiSelectOptionProps {
  id: number
  value: string
  selected: boolean
}

export interface MultiselectProps {
  onSelectionChange: (selectedOptions: number[]) => void
  label?: string
  contentClassName?: string
  triggerClassName?: string
  options: MultiSelectOptionProps[]
  selectedOptions?: number[],
  children?: React.ReactNode
}

const MultiSelect: React.FC<MultiselectProps> = ({
  onSelectionChange,
  label = 'Select',
  contentClassName = '',
  triggerClassName = '',
  options = [],
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedOptions = options.filter(option => option.selected).map(option => option.id)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const updatePopoverWidth = () => {
    if (triggerRef.current && contentRef.current) {
      contentRef.current.style.width = `${triggerRef.current.offsetWidth}px`
    }
  }

  useEffect(() => {
    updatePopoverWidth()
    window.addEventListener('resize', updatePopoverWidth)
    return () => {
      window.removeEventListener('resize', updatePopoverWidth)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        updatePopoverWidth()
      }, 1) // small wait for the popover to be rendered and get a width
    }
  }, [isOpen])

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          type='button'
          variant='multiselect'
          className={cn('flex items-center justify-between w-full form-input input-border-color', triggerClassName)}
          ref={triggerRef}
        >
          <span>{label}</span><ChevronDown className='ml-2 h-4 w-4' />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn('form-input border-t-0 !rounded-t-none overflow-hidden', contentClassName)}
        sideOffset={-6}
        align='start'
        ref={contentRef}
      >
        {options.map(option => (
          <div key={option.id} className="flex items-center space-x-2 h-10 w-full">
            <Checkbox
              id={option.id.toString()}
              className='w-4 sm:w-5 h-4 sm:h-5'
              checked={selectedOptions.includes(option.id)}
              onCheckedChange={
                (checked: boolean) => {
                  const newSelection = checked
                    ? [...selectedOptions, option.id]
                    : selectedOptions.filter(id => id !== option.id)

                  onSelectionChange(newSelection)
                }
              }
            />
            <Label className='cursor-pointer' htmlFor={option.id.toString()}>{option.value}</Label>
          </div>
        ))}
        { children }
      </PopoverContent>
    </Popover>
  )
}

export default MultiSelect
