import { useEffect, useState, useMemo } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'hooks/userToast.hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'

import { CommitmentsResponse, OpportunityResponse } from 'types/api-types'
import { EntitiesResponse } from 'helpers/api/get-api'
import { useGetCommitmentsByOrganizationAndOpportunityId, useGetEntitesByUserQuery, useGetOpportunity, useMemberQuery } from 'hooks/api/useQuery.hooks'
import { useAddCommitmentMutate, useUpdateCommitmentMutate, useDeleteCommitmentMutate } from 'hooks/api/useMutation.hooks'
import { CreateCommitmentParams, Entity } from 'helpers/api/post-api'
import { commitmentKeyFactory, userKeyFactory } from 'helpers/api/factories/userKey'

import { isAdmin } from 'services/authentication'
import { Opportunity } from 'services/DataStore'
import { dollarFormat } from 'constants/DollarsFormat'
import { ManageEntitiesDialog } from 'components/modals/manageEntities/ManageEntitiesDialog'

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { CurrencyInput } from 'components/ui/CurrencyInput'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import { Section } from '../deal/layouts/Section'
import { Article } from '../deal/layouts/Article'
import { DialogFooter } from 'components/ui/Dialog'
import { ConfirmAlert } from 'components/modals/commitments/components/ConfirmAlert'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { Checkbox } from 'components/ui/Checkbox'
import { Trash2, ExternalLink } from 'lucide-react'
import CommitmentOpportunitySelectorCard from './CommitmentOpportunitySelectorCard'
import EntityCommitmentFeeAndCapital from 'components/EntityCommitmentFeeAndCapital'
import MultiSelect from 'components/ui/MultiSelect'

interface Props {
  commitment?: CommitmentsResponse | undefined
  toggleOpen: (open: boolean) => void,
  userId?: number
}

// this props are just to simplify form
interface CommitmentFormEntity extends Entity {
  name: string | undefined
  org_opp_commitment_id: number
  selected: boolean
}

const commitmentSchema = z.object({
  disable_email_notif: z.boolean(),
  entities: z.array(z.object({
    id: z.number().int().positive().optional(),
    carry_share: z.number().min(0).max(100),
    selected: z.boolean().optional(),
    amount_requested: z.number().positive(),
  }))
})

type CommitmentsSchema = z.infer<typeof commitmentSchema>

interface Callbacks {
  onSuccess: () => Promise<void>
  onError: (error: { message?: string }) => void
}

const VINTAGE_PREF_LIMIT_0 = 0
const VINTAGE_PREF_LIMIT_1 = 500000
const VINTAGE_PREF_LIMIT_2 = 1000000
const VINTAGE_PREF_LIMIT_3 = 2500000

const DEALSHARE_FEE_PERCENT = 2
const DEALSHARE_FEE_PERCENT_0 = 1.5
const DEALSHARE_FEE_PERCENT_1 = 1
const DEALSHARE_FEE_PERCENT_2 = 0.5
const DEALSHARE_FEE_PERCENT_3 = 0

const DEALSHARE_FEE_YEARS = 10
const SINGLE_MGMT_FEE_PERCENT = 2

const calculateFees = (opportunity: Opportunity & OpportunityResponse, commitmentsByOrgEntities: CommitmentsResponse[] | undefined, totalInvestment: number) => {
  let feeAmount = 0
  let feePercent = 0
  let totalCapital = 0
  let initialCapital = 0

  if (opportunity.isDealshare()) {
    const maxFundInvestment = Math.max(
      0,
      ...commitmentsByOrgEntities?.map(e => parseFloat(e.final_amount_total || e.amount_requested)) || []
    )

    if (maxFundInvestment > VINTAGE_PREF_LIMIT_3) {
      feePercent = DEALSHARE_FEE_PERCENT_3
    } else if (maxFundInvestment > VINTAGE_PREF_LIMIT_2) {
      feePercent = DEALSHARE_FEE_PERCENT_2
    } else if (maxFundInvestment > VINTAGE_PREF_LIMIT_1) {
      feePercent = DEALSHARE_FEE_PERCENT_1
    } else if (maxFundInvestment > VINTAGE_PREF_LIMIT_0) {
      feePercent = DEALSHARE_FEE_PERCENT_0
    } else {
      feePercent = DEALSHARE_FEE_PERCENT
    }
  }

  if (feePercent > 0) {
    const annualFee = (totalInvestment * feePercent) / 100
    feeAmount = annualFee * DEALSHARE_FEE_YEARS
    totalCapital = totalInvestment + annualFee * DEALSHARE_FEE_YEARS
    initialCapital = totalInvestment + annualFee
  } else {
    feeAmount = (totalInvestment * SINGLE_MGMT_FEE_PERCENT) / 100
    totalCapital = totalInvestment + feeAmount
    initialCapital = totalInvestment + feeAmount
  }

  return { feeAmount, feePercent, totalCapital, initialCapital }
}

export function CommitmentsForm({ commitment, toggleOpen, userId }: Props) {
  const form = useForm<CommitmentsSchema>({resolver: zodResolver(commitmentSchema)})
  const queryClient = useQueryClient()
  const [opportunityId, setOpportunityId] = useState<number | undefined>(commitment?.opportunity_id)
  const [entityToRemove, setEntityToRemove] = useState<CommitmentFormEntity | undefined>(undefined)
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false)
  const { toast } = useToast()
  const { mutate: addCommitment } = useAddCommitmentMutate()
  const { mutate: updateCommitment } = useUpdateCommitmentMutate()
  const { mutate: deleteCommitment, isLoading: isDeletingCommitment } = useDeleteCommitmentMutate()
  const { data : user } = useMemberQuery(userId)
  const { data : userEntities, isFetching: isFetchingUserEntites } = useGetEntitesByUserQuery(userId)
  const { data : commitmentsByOrgEntities, isFetching: isFetchingCommitmentEntities }
    = useGetCommitmentsByOrganizationAndOpportunityId(opportunityId, user?.organization?.id)
  const { data: opp, isLoading: isLoadingOpportunity } = useGetOpportunity(`${opportunityId || ''}`)
  const opportunity = Object.assign(new Opportunity(), opp)

  const entities: CommitmentFormEntity[] = userEntities?.map((userEntity: EntitiesResponse) => {
    const commitmentEntity = commitmentsByOrgEntities?.find((commEntity: CommitmentsResponse) => commEntity.entity_id === userEntity.id)

    return {
      entity_id: userEntity.id,
      name: userEntity.name,
      amount_requested: parseFloat(commitmentEntity?.amount_requested ?? '0'),
      carry_share: parseFloat(commitmentEntity?.carry_share ?? '100'),
      selected: !!commitmentEntity,
      org_opp_commitment_id: commitmentEntity?.id,
    } as CommitmentFormEntity
  }) || []

  const [showManageEntities, setShowManageEntities] = useState(false)
  const [selectedEntitiesIds, setSelectedEntitiesIds] = useState<number[]>([])
  const [entitiesInForm, setEntitiesInForm] = useState<CommitmentFormEntity[]>([])

  const selectedEntities = entities.filter(e => selectedEntitiesIds.includes(e.entity_id!))

  const totalInvestment = useMemo(() => {
    return entitiesInForm.map(e => e.amount_requested || 0).reduce((prev, curr) => prev + curr, 0)
  }, [entitiesInForm])

  const minKey = useMemo(() => {
    return !opportunity?.isDealshare() ? 'minimum_commitment' : user?.platform === 'champion' ? 'minimum_collection_commitment' : 'minimum_vintage_commitment'
  }, [opportunity, user])

  const maxKey = useMemo(() => {
    return !opportunity?.isDealshare() ? 'maximum_commitment' : user?.platform === 'champion' ? 'maximum_collection_commitment' : 'maximum_vintage_commitment'
  }, [opportunity, user])

  const maximum = useMemo(() => {
    return (opportunity as Opportunity & OpportunityResponse)[maxKey] || 0
  }, [opportunity, maxKey])

  const minimum = useMemo(() => {
    return (opportunity as Opportunity & OpportunityResponse)[minKey] || 0
  }, [opportunity, minKey])

  const isValidAmount = useMemo(() => {
    return isAdmin() || totalInvestment >= minimum && (!maximum || totalInvestment <= maximum)
  }, [totalInvestment, minimum, maximum])

  const { feeAmount, feePercent, totalCapital, initialCapital } = useMemo(
    () => calculateFees(opportunity, commitmentsByOrgEntities, totalInvestment),
  [opportunity, commitmentsByOrgEntities, totalInvestment])

  useEffect(() => {
    if (!form.getValues().entities) return

    const selection = entities.filter(e => selectedEntitiesIds.includes(e.entity_id!))

    form.reset({
      disable_email_notif: false,
      entities: selection.map(e => {
        const entityInForm = entitiesInForm.find(entityInForm => entityInForm.entity_id === e.entity_id)

        return {
          ...e,
          id: e.entity_id,
          carry_share: entityInForm?.carry_share ?? e.carry_share ?? 100,
          selected: true,
          amount_requested: entityInForm?.amount_requested ?? e.amount_requested ?? 0,
        } as CommitmentsSchema['entities'][0]
      }),
    })
  }, [selectedEntitiesIds])

  useEffect(() => {
    // unless the form is already filled (meaning user could already have selected entities)
    if (form?.getValues()?.entities) return

    // wait for the user and org commitment entities
    if (!isFetchingUserEntites && !isFetchingCommitmentEntities) {
      // to set the preselected entities
      const backendPreselectedEntitiesIds = commitment?.id ?
        entities.filter(e => e.selected).map(e => e.entity_id).filter((id): id is number => id !== undefined)
        : [entities[0]?.entity_id] // preselect first entity if is new commitment

      setSelectedEntitiesIds(backendPreselectedEntitiesIds)
      const entitiesToBeInForm = entities.filter(e => backendPreselectedEntitiesIds.includes(e.entity_id!))
      setEntitiesInForm(entitiesToBeInForm)

      form.reset({
        disable_email_notif: false,
        entities: entitiesToBeInForm.map(e => ({
          id: e.entity_id,
          carry_share: Number(e.carry_share),
          selected: e.selected,
          amount_requested: e.amount_requested,
        })),
      })
    }
  }, [isFetchingUserEntites, isFetchingCommitmentEntities])

  const callbacks: Callbacks = {
    onSuccess: async () => {
      toast({
        variant: 'success',
        description: `Commitment ${commitment?.id ? 'updated' : 'created'} successfully`,
      })
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: userKeyFactory.commitments(user?.id)
        }),
        queryClient.invalidateQueries({
          queryKey: commitmentKeyFactory.commitmentsByOrganizationAndOpportunityId(opp?.id, user?.organization?.id)
        }),
        queryClient.invalidateQueries({
          queryKey: userKeyFactory.entities(user?.id)
        })
      ])
      setShowConfirmSubmit(false)
    },
    onError: error => {
      toast({
        variant: 'destructive',
        description: error.message ?? 'Something went wrong',
        duration: 2000,
      })
    },
  }

  const callbacksWithAutoClose: Callbacks = {
    onSuccess: async () => {
      callbacks.onSuccess()
      toggleOpen(false)
    },
    onError: callbacks.onError,
  }

  const createCommitments = (entitiesToCreate: CommitmentFormEntity[]) => {
    if (!user?.id) return

    entitiesToCreate.forEach(entity => {
      const params: CreateCommitmentParams = {
        user_id: user?.id,
        entity_id: entity.entity_id,
        commitments: [
          {
            amount_requested: entity.amount_requested,
            carry_share: entity.carry_share,
            disable_email_notif: entity.disable_email_notif,
            entity_id: entity.entity_id,
            opportunity_id: opportunity.id,
            user_id: user?.id
          }
        ]
      }
      addCommitment(params, callbacksWithAutoClose)
    })
  }

  const entitiesWithFormValues = (formValues: CommitmentsSchema) => {
    return formValues.entities.map(entityInForm => {
      const backendEntity = entities.find(e => e.entity_id === entityInForm.id)

      return {
        ...backendEntity,
        amount_requested: entityInForm.amount_requested,
        carry_share: entityInForm.carry_share,
        disable_email_notif: formValues.disable_email_notif,
      } as CommitmentFormEntity
    })
  }

  const handleSubmit = () => {
    form.handleSubmit(submitForm)()
  }

  const submitForm: SubmitHandler<CommitmentsSchema> = (values) => {
    const entitiesInForm = entitiesWithFormValues(values)
    const backendEntities = entities.filter(entity => commitmentsByOrgEntities?.find(e => e.entity_id === entity.entity_id))
    const entitiesToCreate = entitiesInForm.filter(entity => !backendEntities.find(e => e.entity_id === entity.entity_id))
    const entitiesToUpdate = entitiesInForm.filter(entity => backendEntities.find(e => e.entity_id === entity.entity_id))
    const entitiesToDelete = backendEntities.filter(e => !selectedEntitiesIds.includes(e.entity_id))

    entitiesToUpdate.forEach(entity => {
      updateCommitment(entity, callbacksWithAutoClose)
    })

    entitiesToDelete.forEach(entity => {
      deleteEntityCommitment(entity, false)
    })

    createCommitments(entitiesToCreate)
  }

  const handleFormChange = (values: CommitmentsSchema) => {
    setEntitiesInForm(entitiesWithFormValues(values))
  }

  const deleteEntityCommitment = (entity: CommitmentFormEntity, anounce = true) => {
    const { org_opp_commitment_id } = entity
    setShowConfirmSubmit(false)

    if (org_opp_commitment_id) {
      deleteCommitment(org_opp_commitment_id, {
        onSuccess: async () => {
          if (anounce) {
            toast({
              variant: 'success',
              description: `Entity deleted successfully`,
            })
          }

          queryClient.invalidateQueries({
            queryKey: commitmentKeyFactory.commitmentsByOrganizationAndOpportunityId(opp?.id, user?.organization?.id)
          })

          setSelectedEntitiesIds(selectedEntitiesIds.filter(id => id !== entity.entity_id))
          setEntitiesInForm(entitiesInForm.filter(e => e.entity_id !== entity.entity_id))
        },
        onError: callbacks.onError
      })
    } else {
      // is a new entity so backend doesn't know about it
      setSelectedEntitiesIds(selectedEntitiesIds.filter(id => id !== entity.entity_id))
    }

    setEntityToRemove(undefined)
  }

  return (
    <>
    <Section>
      <Article>
        <CommitmentOpportunitySelectorCard
          defaultOpportunityId={commitment?.opportunity_id}
          onSelect={(item) => { setOpportunityId(item.id) }}
          onClear={() => { setOpportunityId(undefined) }}
          user={user}
          minimum={minimum}
          maximum={maximum}
        />
      </Article>
    </Section>
    <Form {...form}>
      <form noValidate>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="flex-1">
            <Card className='h-full'>
              <CardHeader className='pb-0'>
                <CardTitle>Entities</CardTitle>
              </CardHeader>
              <CardContent className='pt-4'>
                {
                  selectedEntities.length
                  ? (
                    selectedEntities.map(entity => (
                      <div className='flex justify-between gap-2 mb-4' key={entity.entity_id}>
                        <FormField
                          control={form.control}
                          name={`entities.${selectedEntities.indexOf(entity)}.amount_requested`}
                          render={({ field }) => {
                            const { onChange, value, ...rest } = field

                            return (<FormItem className={isAdmin() ? 'w-3/4' : 'w-full'}>
                              <FormLabel>{entity.name}</FormLabel>
                              <FormControl>
                                <CurrencyInput
                                  {...rest}
                                  value={value}
                                  onValueChange={val => {
                                    val !== undefined && onChange(+val)
                                    handleFormChange(form.getValues())
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}}
                        />
                        {isAdmin() &&
                          <>
                            <FormField
                              key={entity.entity_id}
                              control={form.control}
                              name={`entities.${selectedEntities.indexOf(entity)}.carry_share`}
                              render={({ field }) => (
                                <FormItem className='w-1/4'>
                                  <FormLabel>Carry</FormLabel>
                                  <FormControl>
                                    <Input
                                      {...field}
                                      value={field.value}
                                      type="number"
                                      min={0}
                                      max={100}
                                      onChange={(e) => {
                                        field.onChange(Number(e.target.value))
                                        handleFormChange(form.getValues())
                                      }}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <Button
                              type='button'
                              size='sm'
                              variant='ghost'
                              className='mt-9'
                              disabled={isDeletingCommitment}
                              onClick={() => setEntityToRemove(entity)}
                            >
                              <Trash2 className='w-4 h-4 text-archived' />
                            </Button>
                          </>
                        }
                      </div>
                    ))
                  )
                  : <p>Select an entity</p>
                }
                <MultiSelect
                  label='Add entity'
                  onSelectionChange={(newSelectedItems) => { setSelectedEntitiesIds(newSelectedItems) }}
                  triggerClassName={isAdmin() ? 'w-[calc(75%-2.25rem)]' : 'w-full'}
                  options={
                    entities.map(entity => ({
                      id: entity.entity_id!,
                      value: entity.name!,
                      selected: selectedEntitiesIds.includes(entity.entity_id!)
                    }))
                  }
                >
                  <hr className="border-t border-gray-200 my-4" />
                  <Button
                    variant='outline'
                    type='button'
                    onClick={() => setShowManageEntities(true)}
                    className='w-full'
                  >
                    Create new entity
                  </Button>
                </MultiSelect>
              </CardContent>
            </Card>
          </div>
          <div className="flex-1">
            <Card className='h-full'>
              <CardContent className='mt-4'>
                <EntityCommitmentFeeAndCapital
                  totalInvestment={totalInvestment}
                  oneTimeFee={feePercent > 0}
                  feeAmount={feeAmount}
                  totalCapital={totalCapital}
                  initialCapital={initialCapital}
                  multipleEntities={selectedEntities.length > 1}
                  isDealshare={opportunity?.isDealshare()}
                />
              </CardContent>
            </Card>
          </div>
        </div>
        <br />
        <Section>
          <Article>
            <div className='flex items-center text-xs'>
              For more information and alternative options on our management fee structure.
              <a href={
                    commitment?.user?.platform === 'champion'
                      ? 'https://mvp-vc.docsend.com/view/mz8msimiewhqis9c'
                      : 'https://mvp-vc.docsend.com/view/eashtrs52u89sive'
                  }
                  rel='noreferrer'
                  target='_blank'><ExternalLink className='w-4 h-4 ml-1' />
              </a>
            </div>
            <br />
            {
              isAdmin() &&
              <div className='space-y-4'>
                <FormField
                  control={form.control}
                  name='disable_email_notif'
                  render={({ field }) => {
                    return (
                      <FormItem className='flex items-center space-y-0 space-x-2'>
                        <FormControl>
                          <Checkbox
                            checked={field.value ?? false}
                            className='w-5 h-5'
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel>Do not send client email notifications</FormLabel>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
              </div>
            }
          </Article>
        </Section>

        <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            className='flex-1 max-w-[262px]'
            onClick={() => toggleOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type='button'
            size='sm'
            className='flex-1 max-w-[262px] bg-success'
            onClick={async () => {
              const isValid = await form.trigger()
              if (isValid) {
                setShowConfirmSubmit(true)
              }
            }}
            disabled={!isValidAmount || !opp || isLoadingOpportunity || selectedEntities.length === 0}
          >
            { commitment?.id ? 'Update' : 'Save' }
          </Button>
        </DialogFooter>
      </form>
    </Form>
    {showManageEntities && (
      <ManageEntitiesDialog
        user={user}
        open={showManageEntities}
        toggleDialog={setShowManageEntities}
      />
    )}
    <ConfirmAlert
      open={!!entityToRemove}
      toggleOpen={() => setEntityToRemove(undefined)}
      header='IMPORTANT'
      message='Are you sure you want to remove this commitment?'
      action={() => deleteEntityCommitment(entityToRemove!)}
    />
    <ConfirmAlert
      open={showConfirmSubmit}
      toggleOpen={setShowConfirmSubmit}
      header='IMPORTANT'
      message={`When you make a commitment, we consider it final unless the deal changes. You cannot commit and then later withdraw. Please confirm your investment of ${dollarFormat(
        totalInvestment
      )} into ${opportunity?.name} here`}
      action={handleSubmit}
    />
    </>
  )
}
