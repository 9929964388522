import { useState } from 'react'
import ReactSelect, { components, type OptionProps } from 'react-select'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Search } from 'lucide-react'
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/Form'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger
} from 'components/ui/Dialog'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'
import { type SearchedUserResponse, useSearchUserQuery } from 'hooks/api/useQuery.hooks'
import useDebounce from 'services/useDebounce'
import { cn } from 'services/utils'
import { useMergeMemberInOrganizationMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { UserInfo } from 'types/api-types'

interface Props {
  currentUser: UserInfo
}

const DEBOUNCE_TIME = 500

function SelectOptions({ data, ...rest }: OptionProps<SearchedUserResponse>) {
  return (
    <components.Option {...rest} data={data}>
      <span className='block'>{data.name}</span>
      <span>{data.email}</span>
    </components.Option>
  )
}

const formSchema = z.object({
  memberToMerge: z
    .object({
      email: z.string(),
      name: z.string(),
      id: z.number(),
      label: z.string(),
      value: z.number()
    })
    .nullable()
})
type FormSchema = z.infer<typeof formSchema>

export function MergeMember({ currentUser }: Props) {
  const { toast } = useToast()

  const [open, setOpen] = useState(false)
  const [searchedTerm, setSearchedTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchedTerm, DEBOUNCE_TIME)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      memberToMerge: null
    }
  })

  const { mutate, isLoading } = useMergeMemberInOrganizationMutate()

  const { data: searchedUsers, isFetching } = useSearchUserQuery(
    {
      id: currentUser?.id + '' ?? '',
      platform: currentUser?.platform ?? '',
      searchTerm: debouncedSearchTerm
    },
    DEBOUNCE_TIME
  )

  const onSubmit: SubmitHandler<FormSchema> = formValue => {
    const { memberToMerge } = formValue
    if (!currentUser || !memberToMerge) return

    mutate(
      { organizationId: currentUser.organization.id, memberId: memberToMerge.id },
      {
        onSuccess: () => {
          setSearchedTerm('')
          form.reset()
          toggleDialogOpen(false)
          toast({
            variant: 'success',
            description: `${memberToMerge.email} has been merged to the organization`,
            duration: 2000
          })
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  const handleSelect = (value: SearchedUserResponse) => {
    form.setValue('memberToMerge', value)
  }

  const toggleDialogOpen = (status: boolean) => {
    setSearchedTerm('')
    form.reset()
    setOpen(status)
  }

  return (
    <Dialog open={open} onOpenChange={toggleDialogOpen}>
      <DialogTrigger asChild>
        <Button size='sm'>Merge Member</Button>
      </DialogTrigger>

      <DialogContent className='max-w-3xl rounded-md'>
        <DialogHeader>
          <h2 className='m-0 text-xl font-medium'>
            <span className='text-2xl'>
              Merge Member into {currentUser?.organization.name}
            </span>
            {currentUser?.organization.name === 'Modern Venture Partner' && (
              <i className='ml-1 text-gray-500 text-sm'>
                (All members of this organization will be admins)
              </i>
            )}
          </h2>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='mt-4'>
            <FormField
              control={form.control}
              name='memberToMerge'
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <ReactSelect
                        {...field}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#5cb85c',
                            primary25: '#5cb85c40'
                          }
                        })}
                        classNames={{
                          control(props) {
                            return cn(props.className ?? '', 'h-12 px-2 !rounded-lg')
                          }
                        }}
                        placeholder={
                          <span className='flex items-center'>
                            <Search className='w-4 h-4' />
                            <span className='ml-2'>Search member</span>
                          </span>
                        }
                        options={searchedUsers}
                        filterOption={null}
                        inputValue={searchedTerm}
                        isClearable
                        isLoading={isFetching && !!searchedTerm}
                        components={{ Option: SelectOptions }}
                        onInputChange={value => setSearchedTerm(value)}
                        onChange={val => handleSelect(val as SearchedUserResponse)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />

            <DialogFooter className='flex items-center sm:justify-start flex-row mt-6'>
              <Button
                type='button'
                className='flex-1 max-w-[262px]'
                onClick={() => toggleDialogOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                className='ml-2 flex-1 max-w-[262px] bg-[#52B749]'
                loading={isLoading}
              >
                Merge
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
