import emptyImage from 'assets/images/image.svg'

function getFilteredStageRollups(props, globalFilter) {
  return new Set(
    [props.row.original, ...props.row.original.follow_ons]
      .filter(
        f =>
          (globalFilter.fund === 'all' || f.fund_ids?.includes(globalFilter.fund)) &&
          !globalFilter.stages.includes(f.stage_rollup)
      )
      .map(f => f.stage_rollup)
  )
}

function sortRows(props) {
  return [props.row.original, ...props.row.original.follow_ons].sort(
    (b, a) => new Date(b.funding_date || b.closes) - new Date(a.funding_date || a.closes)
  )
}

export default function dealsTableCols(globalFilter = { fund: 'all', stages: [] }) {
  return [
    {
      header: 'Company',
      accessorFn: data => data.logo.url,
      cell: props => {
        const stages = getFilteredStageRollups(props, globalFilter)
        const sortedRow = sortRows(props)

        const dates = []
        const investors = []
        for (const row of sortedRow) {
          if (!stages.has(row.stage_rollup)) continue
          dates.push(row.funding_date || row.closes)
          if (row.investor) investors.push(row.investor)
          stages.delete(row.stage_rollup)
        }

        return (
          <div className='flex items-center justify-between gap-2'>
            <img
              className='rounded-md p-1 min-h-[33px] max-w-[42px] lg:min-h-[110px] sm:max-w-[100px] max-h-[50px] lg:max-h-[110px] w-full object-contain brightness-0'
              onError={e => (e.target.src = emptyImage)}
              src={`${props.getValue()}`}
            />
            <div className='flex flex-col gap-1 md:hidden flex-1 text-[12px]'>
              <a
                href={props.row.original.company_url}
                target='_blank'
                rel='noreferrer'
                className='text-[#6DB557] underline underline-offset-2 font-[600] montserrat'
                onClick={e => e.stopPropagation()}
              >
                {props.row.original.name}
              </a>
              <p className='m-0 text-[12px] montserrat'>
                {props.row.original.sector} -{' '}
                {Array.from(getFilteredStageRollups(props, globalFilter)).join(', ')}
              </p>
            </div>

            <div className='flex flex-col gap-1 md:hidden text-right'>
              <p className='m-0 text-[12px] montserrat'>
                {investors.length > 0 ? investors.join(', ') : props.row.original.investor}
              </p>
              <p className='m-0 text-[12px] montserrat'>
                {dates
                  .map(d =>
                    new Date(d).toLocaleDateString('en-us', {
                      month: 'short',
                      year: 'numeric'
                    })
                  )
                  .join(', ')}
              </p>
            </div>
          </div>
        )
      }
    },
    {
      accessorKey: 'name',
      header: () => 'Name',
      cell: props => {
        return (
          <div className='flex'>
            <a
              href={props.row.original.company_url}
              target='_blank'
              rel='noreferrer'
              className='text-[#6DB557] underline underline-offset-2'
              onClick={e => e.stopPropagation()}
            >
              {props.getValue()}
            </a>
          </div>
        )
      }
    },
    {
      header: 'Sector',
      accessorKey: 'sector'
    },
    {
      accessorKey: 'stage_rollup',
      cell: props => Array.from(getFilteredStageRollups(props, globalFilter)).join(', '),
      header: 'Stage'
    },
    {
      header: 'Date',
      accessorFn: data => new Date(data.funding_date || data.closes),
      sortType: 'date',
      cell: props => {
        const stages = getFilteredStageRollups(props, globalFilter)
        const dates = []
        for (const row of sortRows(props)) {
          if (!stages.has(row.stage_rollup)) continue
          dates.push(row.funding_date || row.closes)
          stages.delete(row.stage_rollup)
        }
        return dates
          .map(d =>
            new Date(d).toLocaleDateString('en-us', {
              month: 'short',
              year: 'numeric'
            })
          )
          .join(', ')
      }
    },
    {
      header: 'Lead Investor',
      accessorKey: 'investor',
      cell: props => {
        const stages = getFilteredStageRollups(props, globalFilter)
        const investors = []
        for (const row of sortRows(props)) {
          if (!stages.has(row.stage_rollup) || !row.investor) continue
          investors.push(row.investor)
          stages.delete(row.stage_rollup)
        }
        return investors.length > 0 ? investors.join(', ') : props.row.original.investor
      }
    }
  ]
}
