import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'components/ui/Button'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { CommitmentsResponse } from 'types/api-types'
import { useRemoveCommitmentById } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { commitmentKeyFactory } from 'helpers/api/factories/userKey'

interface Props {
  opportunityId: number
  id: number
  open: boolean
  onClose: () => void
  onUnSelectRow: () => void
}

export function DeleteCommitmentDialog({ opportunityId, id, open, onClose, onUnSelectRow }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useRemoveCommitmentById()

  const handleDelete = async () => {
    mutate(id, {
      onSuccess: data => {
        queryClient.setQueryData<CommitmentsResponse[]>(
          commitmentKeyFactory.commitmentsByOpportunityId(opportunityId),
          oldData => {
            if (!oldData) return undefined
            return oldData.filter(d => d.id !== id)
          }
        )
        toast({
          variant: 'success',
          description: 'Commitment deleted'
        })
        onClose()
        onUnSelectRow()
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  return (
    <AlertDialog open={open} onOpenChange={onClose}>
      <AlertDialogContent className='max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Commitment</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription className='flex flex-col'>
          <span>Are you sure you want to the remove this commitment?</span>
        </AlertDialogDescription>

        <AlertDialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='flex-1 max-w-[262px]'
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            type='button'
            loading={isLoading}
            size='sm'
            className='flex-1 max-w-[262px] bg-archived'
            onClick={handleDelete}
          >
            Delete
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
